import { useApiFetch, ApiFetchOptions } from '@/shared'

export type Brokerclient = {
  id: number
  deleted_at: null
  title: string
  staff_ids: number[]
}

const baseUrl = 'brokerclient/'

/** Получение ТТ по её ID */
export const useBrokerclientGet = () =>
  useApiFetch<{ _data: { id: number } }, Brokerclient>(baseUrl + 'get', {}, 'json')

/** Получить список ТТ. Есть возможность фильтровать список */
export const useBrokerclientGetList = () =>
  useApiFetch<ApiFetchOptions, Brokerclient[]>(baseUrl + 'get_list', {}, 'json')
