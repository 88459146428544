/** Дата в формате front'a */
export function dateFrontFormat(date: string) {
  const parts = date.split('-')
  // if (parts.length !== 3) {
  //   return 'Неверный формат даты'
  // }

  const year = parts[0]
  const month = parts[1]
  const day = parts[2]
  return `${day}.${month}.${year}`
}
