<template>
  <div
    class="relative bg-white flex flex-col"
    :class="{
      'opacity-40 pointer-events-none': disabled
    }"
  >
    <div
      class="text-slate-700 text-sm p-4 border border-solid border-slate-300 rounded-t-md"
      :class="{
        '!border-red-500': invalid
      }"
    >
      {{ label }}
    </div>

    <div
      v-if="filter"
      class="p-4 border border-solid border-slate-300 border-t-0 border-b-0"
      :class="{
        '!border-red-500': invalid
      }"
    >
      <InputText
        v-model="filterText"
        size="small"
        class="w-full"
        placeholder="поиск"
        :disabled="disabled"
        @input="handleFilterInput"
      />
    </div>

    <div
      ref="scrollContainer"
      class="max-h-[350px] min-h-[350px] outline-none border-t-0 border border-solid border-slate-300 rounded-t-none rounded-md overflow-auto"
      tabindex="0"
      :class="{
        '!border-red-500': invalid
      }"
      @keydown="handleKeyDown"
      @scroll="handleScroll()"
    >
      <div
        v-for="item in filteredItems"
        :key="item.id"
        class="text-sm px-4 py-2 cursor-pointer"
        :class="{
          'bg-primary text-white': itemIsSelected(item),
          'text-slate-900 hover:bg-slate-200': !itemIsSelected(item)
        }"
        @click="selectItem(item, $event)"
        @dblclick="handleDblclick(item, $event)"
      >
        {{ getItemLabel(item) }}
      </div>

      <div
        v-if="loading"
        class="text-slate-500 px-4 py-2"
      >
        Загрузка...
      </div>

      <div
        v-if="showMore"
        class="text-slate-700 px-4 py-2 cursor-pointer hover:bg-slate-200 transition-colors"
        @click="emits('showMore')"
      >
        Показать еще...
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineModel } from 'vue'
import InputText from 'primevue/inputtext'

type Item = Record<string, any>
type GetKeyFn = (item: unknown) => string

const props = withDefaults(
  defineProps<{
    items: Item[]
    label: string
    lazy?: boolean
    filter?: boolean
    loading?: boolean
    invalid?: boolean
    showMore?: boolean
    disabled?: boolean
    itemLabel?: string | GetKeyFn
    itemValue?: string | GetKeyFn
  }>(),
  {
    itemLabel: 'title',
    itemValue: 'code'
  }
)

const emits = defineEmits<{
  showMore: []
  loadMore: []
  search: [string | null]
  itemDblclick: [Item, MouseEvent]
}>()

const filterText = ref<string>('')
const selectedItems = defineModel<(number | string)[]>('selected', { default: [] })
const lastSelectedId = ref<number | string | null>(null)

const scrollContainer = ref<HTMLDivElement>()
let previousScrollTop = 0

const handleScroll = () => {
  if (scrollContainer.value) {
    const { scrollHeight, scrollTop, clientHeight } = scrollContainer.value
    const isScrollingDown = scrollTop > previousScrollTop

    if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 50) {
      emits('loadMore')
    }

    previousScrollTop = scrollTop
  }
}

const filteredItems = computed(() => {
  if (props.lazy) {
    return props.items
  }

  const filter = filterText.value.toLowerCase()
  return props.items.filter(item => getItemLabel(item).toLowerCase().includes(filter))
})

const handleFilterInput = (): void => {
  if (props.lazy) {
    emits('search', filterText.value)
  }
}

const getItemValue = (item: Item): number | string => {
  if (typeof props.itemValue === 'function') {
    return props.itemValue(item)
  }

  return item[props.itemValue]
}

const getItemLabel = (item: Item): string => {
  if (typeof props.itemLabel === 'function') {
    return props.itemLabel(item)
  }

  return item[props.itemLabel]
}

const selectItem = (item: Item, event: MouseEvent): void => {
  if (event.ctrlKey) {
    handleCtrlSelect(getItemValue(item))
  } else if (event.shiftKey) {
    handleShiftSelect(getItemValue(item))
  } else {
    handleSingleSelect(getItemValue(item))
  }

  lastSelectedId.value = getItemValue(item)
}

const handleCtrlSelect = (id: number | string): void => {
  if (selectedItems.value.includes(id)) {
    selectedItems.value = selectedItems.value.filter(selectedId => selectedId !== id)
  } else {
    selectedItems.value.push(id)
  }
}

const handleShiftSelect = (id: number | string): void => {
  if (lastSelectedId.value !== null) {
    const startIndex = props.items.findIndex(item => getItemValue(item) === lastSelectedId.value)
    const endIndex = props.items.findIndex(item => getItemValue(item) === id)

    if (startIndex !== -1 && endIndex !== -1) {
      const [start, end] = [startIndex, endIndex].sort((a, b) => a - b)
      const newSelectedIds = props.items.slice(start, end + 1).map(item => getItemValue(item))
      selectedItems.value = [...new Set(selectedItems.value.concat(newSelectedIds))]
    }
  }
}

const handleSingleSelect = (id: number | string): void => {
  if (selectedItems.value.includes(id)) {
    selectedItems.value = selectedItems.value.filter(item => item !== id)
  } else {
    selectedItems.value = [id]
  }
}

const handleKeyDown = (event: KeyboardEvent): void => {
  if (event.key === 'a' && event.ctrlKey) {
    event.preventDefault()
    selectedItems.value = props.items.map(item => getItemValue(item))
  }
}

const handleDblclick = (item: Item, event: MouseEvent) => {
  emits('itemDblclick', item, event)
}

const itemIsSelected = (item: Item) => {
  return selectedItems.value.includes(getItemValue(item))
}
</script>
