import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import mortgageRoutes, { mortgageRouteNames } from '@/pages/mortgage'
import { routesNames, authRoutes, chatmessageRoutes, reportsRoutes, userRoutes } from './routes'
import { authGuard } from './guards'

const notFoundRoute: RouteRecordRaw = {
  path: '/:pathMatch(.*)',
  redirect: { name: mortgageRouteNames.list }
}

const authorizedPart: RouteRecordRaw = {
  path: '',
  component: () => import('@/shared/components/TheLayout.vue'),
  beforeEnter: [authGuard],
  children: [mortgageRoutes, reportsRoutes, userRoutes, chatmessageRoutes, {
    path: 'domrf',
    name: 'Domrf',
    component: () => import('@/pages/DomrfPage'),
  }]
}

const root: RouteRecordRaw = {
  path: '',
  name: 'Root',
  children: [authorizedPart, authRoutes, notFoundRoute]
}

if (process.env.NODE_ENV === 'development') {
  authorizedPart.children.push({
    path: 'test',
    children: [
      {
        path: 'calculator',
        name: 'test-calc',
        component: () => import('@/pages/TEST/MortgageCalculatorPage')
      },
      {
        path: 'app/:id?',
        name: 'test-app',
        component: () => import('@/pages/TEST/MortgageAppPage')
      }
    ]
  })
}

const router = createRouter({
  history: createWebHistory(),
  routes: [root]
})

router.beforeResolve((to, from, next) => {
  // Находим название страницы, если он прописан в мета данных
  const title = to.matched.reduce((t, r) => (t = (r?.meta.title as string) || t), 'Simpleloan Ипотека')
  window.document.title = title

  next()
})

export { router, routesNames }
