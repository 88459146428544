import { DictValue } from '@/shared'
import { createMortgageModel } from './mortgage-model'
import { useCreateModel } from '@/shared'

/** Форма калькулятора */
export type TMortgagesCalculatorForm = {
  mortgage_type: DictValue | null
  // apartment_type: DictValue | null
  real_estate_object: DictValue | null
  type_of_property: DictValue | null
  apartment_price: number | null
  down_payment: number | null
  credit_amount: number | null
  credit_term: number | null
  has_maternal_capital: boolean
  maternal_capital_sum: number | null
  is_pledge: boolean
  refinancing: boolean,
  pledge_type: DictValue | null
  pledge_readiness: DictValue | null
  pledge_object: DictValue | null
  apartment_city: string | null
  apartment_region: string | null
  combo_flag: boolean
  maternal_capital_in_down_payment: boolean
  maternal_capital_in_loan_sum: boolean
  apartment_city_code: string | null
}

export const useMortgageStore = useCreateModel('mortgageStore', () => {
  //** Текущая модель с которой ведется работа */
  const mortgageModel = createMortgageModel()

  // Часть c таблицей

  return {
    mortgageModel
  }
})
