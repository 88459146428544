<template>
  <div class="flex items-center gap-4 justify-between mb-3">
    <div class="text-lg text-gray-900 font-medium">{{ label }}</div>
    <Button
      v-if="close"
      icon="pi pi-times"
      severity="secondary"
      text
      rounded
      aria-label="Cancel"
      @click="emits('close')"
    />
  </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';


const props = withDefaults(defineProps<{
  label: string,
  close?: boolean
}>(), {
  close: true
})


const emits = defineEmits<{
  close: []
}>()
</script>