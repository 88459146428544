import { ModelType, useModel } from '@/shared'
import { Ref, computed, reactive, ref, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { userIsAdmin } from '@/shared'
import { useSalespointStore, useWebsocketStore } from '@/store'
import { MortgageModel, createMortgageModel } from './mortgage-model'
import { mortgageApi } from '../api'
import { storeToRefs } from 'pinia'
import { useAuthModel } from '@/features/auth'

const LC_MORTGAGE_LIST_PAGE_SIZE = 'mortgage_list_page_size'

function getMortgageListPageSizeFromLS() {
  const baseValue = 5
  return Number(localStorage.getItem(LC_MORTGAGE_LIST_PAGE_SIZE)) || baseValue
}

function setMortgageListPageSizeToLS(pageSize: number) {
  localStorage.setItem(LC_MORTGAGE_LIST_PAGE_SIZE, pageSize.toString())
}

export type MortgageTableFilter = {
  id: number | null
  last_name: string | null
  status: string | null
  salespoint_id: number | null
  date1: string | null
  date2: string | null
  credit_amount: string | null
}

// type ModelType = 'local' | 'provide'

// function useModel<T>(name: string): T
// function useModel<T>(name: string, type: ModelType): T
// function useModel<T>(name: string, type: ModelType, model: T): T
// function useModel<T>(name: string, type: ModelType = 'provide', model?: T): T {
//   if (type === 'provide') {
//     return useProvide(name, model)
//   }

//   return model as T
// }

export type MortgageTableModel = {
  mortgageModels: Ref<MortgageModel[] | null>
  startIndex: Ref<number>
  pageNumber: Ref<number>
  pageSize: Ref<number>
  totalSize: Ref<number>
  getMortgages(): void
  getMortgagesPending: Ref<boolean>
  pending: Ref<boolean>
  filter: {
    id: number | null
    last_name: string | null
    status: string | null
    salespoint_id: number | null
    date1: string | null
    date2: string | null
    credit_amount: string | null
  }
  drop(): void
}

const createMortgageTableModel = (): MortgageTableModel => {
  // Уведомления
  const { add: addToast } = useToast()
  // Store авторизации. Тут нужен для получения текущего юзера
  const authModel = useAuthModel()
  // Store с выбранной тт
  const { salespoint } = storeToRefs(useSalespointStore())
  // Сообщения из сокета
  const { message } = storeToRefs(useWebsocketStore())
  // Список моделей. Список заявок обернутых в модели
  const mortgageModels = ref<MortgageModel[] | null>(null) as Ref<MortgageModel[] | null>
  // Размер страницы
  const pageSize = ref<number>(getMortgageListPageSizeFromLS())
  // Номер страницы
  const pageNumber = ref<number>(0)
  // Общие кол-во найденных заявок.
  const totalSize = ref<number>(0)
  // Начальный индекс. Начиная с какого индекса нам нужны заявки
  const startIndex = computed(() => pageNumber.value * pageSize.value)
  // Индикатор загрузки
  const pending = ref<boolean>(false)
  // Фильтр поиска
  const filter = reactive<MortgageTableFilter>({
    id: null,
    last_name: null,
    status: null,
    salespoint_id: null,
    date1: null,
    date2: null,
    credit_amount: null
  })
  // Api для получения списка заявок
  const {
    post: getListPost,
    pending: getMortgagesPending,
    abort: getListAbort,
    error: getListError
  } = mortgageApi.getList()

  // Сохраняет размер таблицы в локальном хранилище
  watch(pageSize, pageSize => setMortgageListPageSizeToLS(pageSize))

  // Обновляет список при изменении страницы или размера таблицы или выбранной ТТ
  watch([pageSize, pageNumber, salespoint], () => getMortgages())

  // Следим за сокетом
  watch(message, message => {
    const { _type, _data } = message
    if (
      authModel.user &&
      salespoint.value &&
      // Если статус сообщения === "создание заявки"
      _type === 'mortgage_create' &&
      // Если юзер админ или заявка привязана к ТТ, которая привязана к юзеру
      (userIsAdmin(authModel.user) || authModel.user.salespoint_staff.includes(_data.app.salespoint_id)) &&
      // Если заявки еще нет в списке. Есть возможность, что добавят руками заявку, например, при копировании передадут в массив сразу и
      // поэтому проверяем, что заявка еще не в списке
      !mortgageModels.value?.find(model => model.mortgage?.id === _data.app.id)
    ) {
      const app = _data.app
      const model = createMortgageModel(app)
      const stopWatch = watch(
        model,
        model => {
          if (model.salespoint && model.user) {
            mortgageModels.value?.unshift(model)
            stopWatch()
          }
        },
        { deep: true }
      )
    }
  })

  function drop() {
    mortgageModels.value = null
    pageNumber.value = 0
    totalSize.value = 0
    pending.value = false
    filter.id = null
    filter.last_name = null
    filter.status = null
    filter.salespoint_id = null
    filter.date1 = null
    filter.date2 = null
    filter.credit_amount = null
  }

  // Функция, которая создает данные фильтрации подходящие под бекенд
  // В зависимости от прав юзера и значений фильтра
  function getFilterForGetMortgages() {
    // Если пользователь не авторизован, то ничего не возвращаем
    if (!authModel.user) return

    // Торговые точки по которым идет фильтрация. Если значение undefined, то в json он не пропишется и
    // значение для данной переменной не пойдет в фильтрацию - подтянет заявки всех ТТ
    let salespoint_id: number[] | undefined = undefined
    // Если в фильтре выбрана ТТ
    if (filter.salespoint_id) {
      // Записываем выбранную в фильтре тт. Подтянет заявки только выбранной ТТ
      salespoint_id = [filter.salespoint_id]
    }
    // Если пользователь не админ и не выбрал в фильтре ТТ
    else if (!userIsAdmin(authModel.user)) {
      // Получает только привязанные к пользователю ТТ
      salespoint_id = authModel.user.salespoint_staff
    }

    return {
      salespoint_id,
      id__icontains: filter.id || undefined,
      main_customer__last_name__icontains: filter.last_name || undefined,
      created_at__date__gte: filter.date1 || undefined,
      created_at__date__lte: filter.date2 || undefined,
      credit_amount: filter.credit_amount || undefined
    }
  }

  // Получение списка заявок
  function getMortgages() {
    // Если юзер не авторизован или не выбрана ТТ, то нельзя запрашивать заявки
    if (!authModel.user || !salespoint.value) return

    // Если уже идет запрос
    if (pending.value) {
      // То нужно обрубить текущий
      getListAbort()
    }

    // Ставим индикатор загрузки активным
    pending.value = true

    // Запрос на получение списка заявок
    getListPost({
      // Фильтрация
      _filter: getFilterForGetMortgages()!,
      // Сортирует список в обратном порядке
      _sort: '-created_at',
      // Размер страницы
      _page_size: pageSize.value,
      // Начальный индекс
      _start_index: startIndex.value
    }).then(res => {
      if (res?._code === 0 && res._data) {
        const modelList = res._data.map(mortgage => createMortgageModel(mortgage))
        if (modelList.length) {
          const stopWatch = watch(
            () => modelList,
            models => {
              const modelNoData = models?.find(model => !model.salespoint || !model.user)
              if (modelNoData) return
              stopWatch()
              pending.value = false
              mortgageModels.value = modelList
              if (res._data_total_size !== undefined) {
                totalSize.value = res._data_total_size
              }
            },
            { deep: true }
          )
        } else {
          pending.value = false
          mortgageModels.value = modelList
          if (res._data_total_size !== undefined) {
            totalSize.value = res._data_total_size
          }
        }
      } else if (getListError.value) {
        if (!((getListError.value as any) instanceof DOMException)) {
          addToast({ severity: 'error', summary: 'Получение списка заявок', detail: getListError })
        }
      }
    })
  }

  return {
    startIndex,
    mortgageModels,
    pageNumber,
    pageSize,
    totalSize,
    getMortgages,
    getMortgagesPending,
    pending,
    filter,
    drop
  }
}

export const useMortgageTableModel = (type: ModelType, model?: MortgageTableModel) =>
  useModel('mortgage-send', createMortgageTableModel, type, model)
