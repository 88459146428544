<template>
  <div class="bg-gray-100">
    <div
      v-if="loadingVisible"
      class="min-h-screen flex items-center justify-center"
    >
      <div class="flex gap-4 justify-start items-center">
        <img
          src="@/assets/inpocket-full-logo.svg"
          width="250"
        />
        <ProgressSpinner
          style="width: 40px; height: 40px"
          strokeWidth="6"
        />
      </div>
    </div>

    <div v-else>
      <Toast />
      <DynamicDialog />

      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, watch } from 'vue'
import { useAuthModel } from '@/features/auth'
import { useSalespointStore } from '@/store'
import { setMode as setApiMode } from 'simpleloan_api'
import { useSelectSalespointModel } from '@/features/salespoint'
import { storeToRefs } from 'pinia'
import Toast from 'primevue/toast'
import DynamicDialog from 'primevue/dynamicdialog'
import ProgressSpinner from 'primevue/progressspinner'
import { useMortgageStore } from '@/entities/mortgage'
import { useMortgageTablePageStore } from '@/pages/mortgage/MortgageTablePage/mortgage-table-page-store'

if (process.env.NODE_ENV === 'development') {
  setApiMode('development')
} else {
  setApiMode('production')
}

const authModel = useAuthModel()
const salespointStore = useSalespointStore()

const { openModal } = useSelectSalespointModel()
const { isAuthorized, checkLoginPending } = storeToRefs(authModel)
const { salespoint, getSalespointPending } = storeToRefs(salespointStore)

const loadingVisible = computed(() => checkLoginPending.value)

useMortgageStore()

useMortgageTablePageStore()

watch(
  [checkLoginPending, isAuthorized, getSalespointPending],
  ([checkLoginPending, isAuthorized, getSalespointPending]) => {
    nextTick(() => {
      if (isAuthorized && !checkLoginPending && !salespoint.value && !getSalespointPending) {
        openModal()
      }
    })
  }
)
</script>
