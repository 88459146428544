<template>
  <div class="w-full h-full">
    <TheHeader />
    <!-- <LayoutHeader
      v-model:data-open-side-bar="sidenavExpended"
      :click-hambuger="toggle"
    /> -->
    <div class="w-full h-full flex">
      <div
        class="flex-grow mt-[60px] h-[calc(100vh-60px)] overflow-auto"
        style="scrollbar-gutter: stable"
      >
        <!-- <div class="container mx-auto"> -->
        <router-view></router-view>

        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import TheHeader from './TheHeader.vue';

const sidenavExpended = ref<boolean>(false)

function toggle() {
  sidenavExpended.value = !sidenavExpended.value
}
</script>
