import { useToast } from 'primevue/usetoast'
import { FileInBase64, base64ToFile, useUtilsPdfMerge } from '@/shared'

export const useUtilsPdfMergeModel = () => {
  const { add: addToast } = useToast()
  const { post, pending, error } = useUtilsPdfMerge()

  async function merge(files: FileInBase64[]) {
    const res = await post({ _data: { files } })

    if (res?._code === 0 && res?._data) {
      const blob = base64ToFile(res._data, 'application/pdf')
      const firstFileName = replaceExtensionToPdf(files[0].name)
      return new File([blob], firstFileName, { type: 'application/pdf' })
    } else {
      addToast({ severity: 'error', summary: 'Объединение файлов', detail: error })
      return null
    }
  }

  function replaceExtensionToPdf(fileName: string) {
    // Найдем позицию последней точки в названии файла
    const lastDotIndex = fileName.lastIndexOf('.');
  
    // Если точка не найдена (нет расширения), просто добавим .pdf к названию файла
    if (lastDotIndex === -1) {
      return fileName + '.pdf';
    }
  
    // Извлекаем часть названия до последней точки и добавляем расширение .pdf
    const nameWithoutExtension = fileName.substring(0, lastDotIndex);
    return nameWithoutExtension + '.pdf';
  }

  return { merge, pending, error }
}
