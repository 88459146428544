<template>
  <div class="grid grid-cols-3 gap-2">
    <label
      v-for="(scan, index) in props.scans"
      :key="index"
      :for="index.toString()"
      class="rounded border border-solid border-gray-200 relative cursor-pointer"
      width="160"
      height="180"
    >
      <img
        :src="scan"
        style="width: 100%"
      />
      <Checkbox
        v-model="checkedIdList"
        :inputId="index.toString()"
        class="absolute bottom-1 right-1"
        name="check"
        :value="index"
      />
    </label>
  </div>

  <BaseDiver />

  <div class="flex gap-2 justify-start items-center">
    <Button
      label="Скачать все"
      @click="downLoadAll"
    />
    <Button
      label="Скачать выделенные"
      :disabled="!checkedIdList.length"
      @click="downLoadChecked"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Checkbox from 'primevue/checkbox'
import { BaseDiver, downloadImage } from '@/shared'
import Button from 'primevue/button'

const checkedIdList = ref<string[]>([])

const props = defineProps<{
  scans: string[]
}>()

const downLoadAll = (): void => {
  props.scans.forEach((href, i) => downloadImage({ href, name: `scan-${i + 1}.jpeg` }))
}

const downLoadChecked = (): void => {
  checkedIdList.value.forEach(index =>
    downloadImage({ href: props.scans[Number(index)], name: `scan-${index + 1}.jpeg` })
  )
}
</script>
