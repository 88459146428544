import { Ref, ref, watchEffect } from 'vue'

type AsyncFn = () => Promise<any>
type Fn = () => void

export function trackedRef<T>(initialValue: T, callback: AsyncFn | Fn) {
  const originalRef = ref<T>(initialValue)
  let isUpdating = false
  let isSetByUser = false

  async function update() {
    isUpdating = true

    if (callback.constructor.name === 'AsyncFunction') {
      await callback()
    } else {
      callback()
    }

    isUpdating = false
  }

  const trackedRef = new Proxy(originalRef, {
    get(target, prop, receiver) {
      if (prop === 'value') {
        if (!isUpdating && !isSetByUser) {
          update()
        } else if (!isUpdating) {
          isSetByUser = false
        }
      }

      return Reflect.get(target, prop, receiver)
    },
    set(target, prop, value, receiver) {
      if (prop === 'value') {
        isSetByUser = true
      }
      return Reflect.set(target, prop, value, receiver)
    }
  })

  return trackedRef
}

type AsyncComputedOptions = {
  lazy?: boolean
}

export function myAsyncComputed<T>(
  initialState: T,
  callback: () => Promise<T>,
  options: AsyncComputedOptions = {}
): Ref<T> {
  const originalRef = ref<T>(initialState) as Ref<T>
  const isActive = ref<boolean>(false)

  const { lazy = false } = options

  function activating() {
    if (isActive.value) return

    watchEffect(async onCleanup => {
      let cancelled = false
      onCleanup(() => {
        cancelled = true
      })

      try {
        const result = await callback()
        if (!cancelled) {
          originalRef.value = result
        }
      } catch (error) {
        if (!cancelled) {
          console.error('Error in async computed function:', error)
        }
      }
    })

    isActive.value = true
  }

  if (lazy) {
    return new Proxy(originalRef, {
      get(target, prop, receiver) {
        if (prop === 'value') {
          activating()
        }

        return Reflect.get(target, prop, receiver)
      }
    })
  } else {
    activating()
    return originalRef
  }
}
