import { DictList } from '@/shared'
import { ComputedRef, ref, toRefs } from 'vue'
import { mortgageApi } from '../../api'
import { useToast } from 'primevue/usetoast'
import { Customer } from '@/entities/customer'
import { useMortgageModel } from '../mortgage-model'
import { useCommondictsGetCustomerDict } from '@/shared'
import { MortgageDoctype, MortgageScan } from '../../types'

/**
 ** Фабричная функция, которая создает модель для работы с сканами кастомера
 */
const useMortgageCustomerScansModel = (customer: ComputedRef<Customer | null>) => {
  const { add: addToast } = useToast()
  const mortgageModel = useMortgageModel()
  const { mortgage } = toRefs(mortgageModel)

  /** Список обязательных сканов */
  const doctypes = ref<MortgageDoctype[] | null>(null)
  const { post: doctypesPost } = mortgageApi.getDoctypes()

  /** Список загруженных сканов */
  const scans = ref<MortgageScan[] | null>(null)
  const { post: scansPost } = mortgageApi.getScans()

  /** Справочник по всем доступным типам документов */
  const documentTypeDict = ref<DictList | null>(null)

  /** Запрос на списка загруженных сканов */
  function getScans() {
    if (mortgage.value?.id && customer.value) {
      scansPost({ _data: { mortgageapp_id: mortgage.value?.id } }).then(res => {
        if (res?._code === 0 && res._data) {
          // Записываем только сканы данного кастомера
          scans.value = res._data?.filter(scan => scan.customer_id === customer.value?.id)
        } else {
          addToast({ severity: 'error', summary: 'Получение списка загруженных сканов', detail: res?._message })
        }
      })
    }
  }

  /** Запрос на получение списка обязательных сканов */
  function getDoctypes() {
    if (mortgage.value?.id && customer.value) {
      return doctypesPost({ _data: { id: mortgage.value?.id } }).then(res => {
        if (res?._code === 0 && res._data) {
          // Записываем только сканы данного кастомера
          doctypes.value = res._data?.filter(doctype => doctype.customer_id === customer.value?.id)
        } else {
          addToast({ severity: 'error', summary: 'Получение списка обязательных сканов', detail: res?._message })
        }
      })
    }
  }

  /** Запрос на получение справочника типа документов */
  function getDocumentTypeDict() {
    useCommondictsGetCustomerDict('DocumentType').then(res => {
      documentTypeDict.value = res || null
    })
  }

  return {
    doctypes,
    scans,
    documentTypeDict,
    getScans,
    getDoctypes,
    getDocumentTypeDict
  }
}

export default useMortgageCustomerScansModel
