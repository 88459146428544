import { defineStore } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { computed, ref, watch } from 'vue'
import { loginResponseAdapter } from '../lib'
import { useSalespointStore } from '@/store'
import { User as OldUserType } from '@/shared'
import { useUserModel } from '@/entities/user'
import { Login, LoginResponse, useAccountCheckLogin, useAccountLogin, useAccountLogout } from 'simpleloan_api'

export const useModel = defineStore('auth-model', () => {
  const toast = useToast()

  const userModel = useUserModel()
  const user = ref<OldUserType | null>(null)
  const salespointStore = useSalespointStore()

  const isAuthorized = computed(() => Boolean(user.value))

  const { post: loginPost, pending: loginPending } = useAccountLogin()
  const { post: checkLoginPost, pending: checkLoginPending } = useAccountCheckLogin()
  const { post: logoutPost, pending: logoutPending } = useAccountLogout()

  async function login(body: Login) {
    if (loginPending.value) return

    const res = await loginPost({ body })

    if (res?._code === 0 && res._data) {
      await onLogin(res._data)
    } else {
      toast.add({
        severity: 'error',
        summary: 'Авторизация',
        detail: loginResponseAdapter(res?._code, res?._message)
      })
    }

    return res
  }

  async function logout() {
    if (logoutPending.value) return

    const res = await logoutPost({ body: {} })

    if (res?._code === 0) {
      user.value = null
      localStorage.removeItem('token')
      salespointStore.drop()
    } else {
      toast.add({ severity: 'error', summary: 'Выход', detail: res?._message })
    }

    return res
  }

  async function checkLogin() {
    if (checkLoginPending.value) return

    const res = await checkLoginPost()

    if (res?._code === 0 && res._data) {
      await onLogin(res._data)
    }

    return res
  }

  async function onLogin(res: LoginResponse) {
    user.value = res.user as any
    userModel.user = res.user as any
    localStorage.setItem('token', res.token)
    await salespointStore.setLocalStorageSalespoint()
  }

  return {
    user,
    isAuthorized,

    login,
    loginPending,

    logout,
    logoutPending,

    checkLogin,
    checkLoginPending
  }
})
