<template>
  <div class="relative">
    <div
      ref="root"
      :class="[
        'w-full bg-white p-4 border border-dashed border-gray-400 rounded-md cursor-pointer min-h-[134px]',
        'transition duration-200',
        { 'hover:bg-gray-100': !isHovered },
        { 'border-primary': isOverDropZone }
      ]"
      @click="handleClick"
    >
      <div
        v-if="model.length"
        class="flex flex-wrap gap-4"
      >
        <BaseFilesItem
          v-for="(file, i) in model"
          :key="i"
          :file="file"
          @remove="removeFile(i)"
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
        />
      </div>

      <div
        v-else
        class="w-full h-full flex justify-center items-center text-gray-400 min-h-[100px]"
      >
        Перетащите файл или нажмите для выбора
      </div>

      <input
        ref="input"
        hidden
        multiple
        type="file"
        accept="image/png, image/jpeg, application/pdf"
        @input="onInput"
      />
    </div>

    <div
      v-if="disabled"
      class="absolute top-0 left-0 w-full h-full bg-gray-200 opacity-40"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { defineModel, ref } from 'vue'
import BaseFilesItem from './BaseFilesItem.vue'
import { useDropZone } from '@vueuse/core'

defineProps<{
  disabled?: boolean
}>()

const model = defineModel<File[]>({ required: true })
const root = ref<HTMLElement | null>(null)
// const files = ref<File[]>([])
const input = ref<HTMLInputElement>()
const isHovered = ref<boolean>(false)

const { isOverDropZone } = useDropZone(root, {
  onDrop
  // dataTypes: ['image/jpeg', 'image/jpeg', 'application/pdf']
})

function onInput(event: Event) {
  const { files } = event.target as HTMLInputElement

  if (files?.length) {
    model.value?.push(...files)
  }

  if (input.value?.value) {
    input.value.value = ''
  }
}

function onDrop(files: File[] | null) {
  if (files?.length) {
    model.value.push(...files)
  }
}

function handleClick(payload: MouseEvent) {
  if (!isHovered.value) {
    input.value?.click()
  }
}

function removeFile(i: number) {
  model.value.splice(i, 1)
  isHovered.value = false
}
</script>
