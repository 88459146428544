import { inject, provide } from "vue";

export const useCreateModel = <T>(modelName: string, createModelFn: () => T) => {
  return () => {
    const model = inject(modelName, null);
    
    if (model === null) {
      const createData = createModelFn();
      provide(modelName, createData);
      return createData;
    }

    return model as T;
  }
}