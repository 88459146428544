export function usePassportIssueDateCheck() {
  const errorMessage = 'Паспорт просрочен'

  const getAge = (birthDate: Date) => {
    // Текущий день
    const today = new Date()
    // Возраст (на момент создание не всегда точный)
    let age = today.getFullYear() - birthDate.getFullYear()
    // Дата дня рождения в этом году
    const dobnow = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate()) //ДР в текущем году
    // Если дата сегоднящнего дня меньше даты дня рождения в этом году, то уменьшаем возраст
    if (today < dobnow) {
      age--
    }
    return age
  }

  const comparison = (firstDate: Date, lastDate: Date) => {
    if (firstDate.getFullYear() > lastDate.getFullYear()) {
      return true
    }

    if (firstDate.getFullYear() === lastDate.getFullYear() && firstDate.getMonth() > lastDate.getMonth()) {
      return true
    }

    if (
      firstDate.getFullYear() === lastDate.getFullYear() &&
      firstDate.getMonth() === lastDate.getMonth() &&
      firstDate.getDate() > lastDate.getDate()
    ) {
      return true
    }

    return false
  }

  // Дней между датой
  const datediff = (first: Date, second: Date) => {
    return Math.round((second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24))
  }

  const check = (_birthDate: string, _passportIssueDate: string) => {
    const birthDate = new Date(_birthDate)
    const issueDate = new Date(_passportIssueDate)
    const personAge = getAge(birthDate)
    const now = new Date()

    // console.log('birthDate', birthDate)
    // console.log('issueDate', issueDate)
    // console.log('personAge', personAge)
    // Если дата выдачи больше текущей даты
    if (comparison(issueDate, now)) {
      return errorMessage
    }

    // Если дата выдачи больше меньше даты рождения
    if (comparison(birthDate, issueDate)) {
      return errorMessage
    }

    if (personAge >= 14 && personAge <= 20) {
      const leftRange = new Date(birthDate)
      leftRange.setFullYear(leftRange.getFullYear() + 14)
      // Если левая граница больше чем дата выдачи
      if (comparison(leftRange, issueDate)) {
        return errorMessage
      }

      const rightRange = new Date(birthDate)
      rightRange.setFullYear(birthDate.getFullYear() + 20)
      // Если правая граница больше чем дата выдачи и если текущая дата больше правой границы
      if (comparison(rightRange, issueDate) && comparison(now, rightRange)) {
        if (datediff(rightRange, now) > 90) {
          return errorMessage
        }
      }
    }

    if (personAge >= 21 && personAge <= 45) {
      const leftRange = new Date(birthDate)
      leftRange.setFullYear(leftRange.getFullYear() + 20)
      // Если левая граница больше даты выдачи и текущая дата больше левый границы
      if (comparison(leftRange, issueDate) && comparison(now, leftRange)) {
        return errorMessage
      }

      const rightRange = new Date(birthDate)
      rightRange.setFullYear(birthDate.getFullYear() + 45)
      // Если правая граница больше чем дата выдачи и если текущая дата больше правой границы
      if (comparison(rightRange, issueDate) && comparison(now, rightRange)) {
        if (datediff(rightRange, now) > 90) {
          return errorMessage
        }
      }
    }

    if (personAge >= 46) {
      const leftRange = new Date(birthDate)
      leftRange.setFullYear(leftRange.getFullYear() + 45)
      // Если левая граница больше даты выдачи и текущая дата больше левый границы
      if (comparison(leftRange, issueDate) && comparison(now, leftRange)) {
        return errorMessage
      }
    }
  }

  return { check }
}
