import { Address, DadataAddress } from '@/shared'

type AddressExpand = {
  code: string
  name: string
  type: string
  type_short: string
}

type AddressExpandCity = {
  code: string
  name: string
  type: string
  type_short: string
  owner?: AddressExpand
}

export function dadataAddressNormalize(title: string, address: DadataAddress): Address {
  let region: AddressExpand | undefined = undefined
  if (address.region) {
    region = {
      code: address.region_kladr_id,
      name: address.region,
      type: address.region_type_full,
      type_short: address.region_type
    }
  }

  let area: AddressExpand | undefined = undefined
  if (address.area) {
    area = {
      code: address.area_kladr_id,
      name: address.area,
      type: address.area_type_full,
      type_short: address.area_type
    }
  }

  let city: AddressExpandCity | undefined = undefined
  if (address.settlement && address.city) {
    city = {
      code: address.settlement_kladr_id,
      name: address.settlement,
      type: address.settlement_type_full,
      type_short: address.settlement_type,
      owner: {
        code: address.city_kladr_id,
        name: address.city,
        type: address.city_type_full,
        type_short: address.city_type
      }
    }
  } else if (address.settlement || address.city) {
    city = {
      code: address.settlement_kladr_id || address.city_kladr_id,
      name: address.settlement || address.city,
      type: address.settlement_type_full || address.city_type_full,
      type_short: address.settlement_type || address.city_type
    }
  }

  let street: AddressExpand | undefined = undefined
  if (address.street) {
    street = {
      code: address.street_kladr_id,
      name: address.street,
      type: address.street_type_full,
      type_short: address.street_type
    }
  }

  return {
    region,
    area,
    city,
    street,
    structure: null,
    flat: address.flat, // квартира
    house: address.house || address.stead, // дои или земельный участок
    building: `${address.block_type || ''} ${address.block || ''}`.trim() || null, // корпус
    postal_code: address.postal_code,
    address_text: title
  }
}
