<template>
  <InputNumber
    v-model="model"
    v-bind="props"
    useGrouping
    mode="currency"
    locale="ru"
    currency="RUB"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
  />
</template>

<script setup lang="ts">
import { defineModel } from 'vue'
import InputNumber, { InputNumberBlurEvent, InputNumberPassThroughOptions } from 'primevue/inputnumber'

const props = withDefaults(
  defineProps<{
    invalid?: boolean
    readonly?: boolean
    disabled?: boolean
    placeholder?: string
    inputId?: string
    pt?: InputNumberPassThroughOptions

    max?: number
    min?: number
    minFractionDigits?: number
    maxFractionDigits?: number
  }>(),
  {
    minFractionDigits: 0
  }
)

const emits = defineEmits<{
  blur: [InputNumberBlurEvent]
  focus: [Event]
}>()

const model = defineModel<number | null>()
</script>
