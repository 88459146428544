<template>
  <PickList
    v-model:selected="selected"
    lazy
    filter
    item-value="id"
    item-label="title"
    first-list-label="Доступные группы торговых точек"
    second-list-label="Выбранные группы торговых точек"
    :items="list"
    :loading="pending"
    :show-more="showMore"
    @search="onSearch"
    @show-more="getList()"
    @load-more="getList()"
  />
</template>

<script setup lang="ts">
import { defineModel } from 'vue'
import { PickList } from '@/shared'
import { useModel } from '../model'

const selected = defineModel<number[] | null>('selected', { default: [], local: true })

const { pending, showMore, onSearch, list, getList } = useModel()

getList()
</script>
