<template>
  <div
    ref="targetElement"
    class="w-fit flex flex-col border border-solid border-gray-200 rounded"
    :class="{
      'bg-primary/15': isAdminMessage,
      'bg-green-700/15': !isAdminMessage
    }"
    @contextmenu.prevent="showContextMenu"
    @mousedown="handleMouseDown"
    @touchstart="handleTouchStart"
  >
    <div
      v-if="userNameVisible"
      class="font-medium p-2 border-b border-gray-400"
    >
      {{ userName }}
    </div>

    <template v-if="fileUlr">
      <Image
        v-if="fileIsImg"
        imageClass="max-h-[230px]"
        preview
        :src="fileUlr"
      />

      <div
        v-if="!fileIsImg"
        class="flex gap-1 items-center p-2 cursor-pointer transition hover:bg-black/25"
        @click="openFile"
      >
        <div class="text-lg pi pi-file-pdf"></div>
        <div>{{ fileName }}</div>
      </div>
    </template>

    <div class="flex items-end justify-between gap-2 p-2">
      <!-- <span [innerHTML]="message"></span> -->
      <span
        class="whitespace-pre-wrap"
        :innerHTML="message.message"
      ></span>
      <div class="flex gap-1">
        <div class="text-xs text-gray-500 text-end">{{ time }}</div>

        <div
          v-if="message.is_read"
          class="relative flex"
        >
          <i class="pi pi-check text-xs text-gray-500 text-end"></i>
          <i class="absolute left-1 pi pi-check text-xs text-gray-500 text-end"></i>
        </div>

        <i
          v-else
          class="pi pi-check text-xs text-gray-500 text-end"
        ></i>
      </div>
    </div>

    <ContextMenu
      ref="contextMenu"
      :model="menuItems"
    />
  </div>
</template>

<script setup lang="ts">
import { useUserModel } from '@/entities/user'
import { getApiFileName, getBaseUrl } from '@/shared'
import ContextMenu from 'primevue/contextmenu'
import Image from 'primevue/image'
import { MenuItem } from 'primevue/menuitem'
import { Chatmessage } from 'simpleloan_api'
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useModel } from '../../model'
import { useToast } from 'primevue/usetoast'
import { getUserName } from '../../lib'

const props = defineProps<{ message: Chatmessage; container: HTMLDivElement; userNameVisible?: boolean }>()

const toast = useToast()
const userModel = useUserModel()
const chatmessageModel = useModel()

const targetElement = ref<HTMLElement | null>(null)
const contextMenu = ref<InstanceType<typeof ContextMenu> | null>(null)
const touchTimeout = ref<number | null>(null)

const fileUlr = computed(() => {
  if (props.message.file) {
    return getBaseUrl() + props.message.file.file
  }

  return null
})

const isMyMessage = computed(() => props.message.user_id === userModel.user?.id)
const isAdminMessage = computed(() => props.message.role_user_msg === 'admin')
const userName = computed(() => getUserName(props.message))

const fileIsImg = computed(() => {
  const fileUlr = props.message.file?.file
  if (fileUlr?.includes('png') || fileUlr?.includes('jpg') || fileUlr?.includes('svg')) {
    return true
  }

  return false
})

const fileName = computed(() => (props.message.file ? getApiFileName(props.message.file) : null))

const uploadDisabled = computed(() => {
  if (!fileUlr.value) {
    return true
  }

  return false
})

const deletePending = ref<boolean>(false)

const deleteDisabled = computed(() => {
  if (deletePending.value) {
    return true
  }

  if (isMyMessage.value) {
    return false
  }

  if (isAdminMessage.value && chatmessageModel.isChatSupport) {
    return false
  }

  return true
})

const menuItems = reactive<MenuItem[]>([
  // { label: props.message.username },
  // { label: 'Скачать файл', icon: 'pi pi-fw pi-save', command: () => downloadFile(), disabled: uploadDisabled.value },
  {
    label: 'Удалить',
    icon: 'pi pi-fw pi-trash',
    command: () => deleteMessage(),
    disabled: deleteDisabled.value
  }
])

const time = computed(() => formatTime(props.message.created_at))

let observer: IntersectionObserver | null = null

onMounted(() => {
  document.addEventListener('touchend', handleTouchEnd)

  if (targetElement.value && !props.message.is_read) {
    observer = setupIntersectionObserver()
    observer.observe(targetElement.value)
  }
})

onBeforeUnmount(() => {
  document.removeEventListener('touchend', handleTouchEnd)

  if (observer) {
    observer.disconnect()
  }
})

function setupIntersectionObserver() {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        handleVisibilityChange(entry)
      })
    },
    {
      root: props.container,
      threshold: 0.1 // процент видимости (0.1 означает, что 10% элемента должно быть видно, чтобы вызывать callback).
    }
  )

  return observer
}

function handleVisibilityChange(entry: IntersectionObserverEntry) {
  const myUserRole = chatmessageModel.isChatSupport ? 'admin' : 'user'
  if (entry.isIntersecting && !props.message.is_read && props.message.role_user_msg !== myUserRole) {
    chatmessageModel.readMessage.post(props.message.id).then(res => {
      if (res?._code === 0) {
        observer?.disconnect()
      }
    })
  }
}

function showContextMenu(event: MouseEvent | TouchEvent) {
  contextMenu.value?.show(event)
}

function formatTime(dateString: string) {
  const date = new Date(dateString)
  let hoursNum = date.getUTCHours()
  let minutesNum = date.getUTCMinutes()

  // Форматирование часов и минут, чтобы они всегда были двумя цифрами
  const hours = hoursNum.toString().padStart(2, '0')
  const minutes = minutesNum.toString().padStart(2, '0')

  return `${hours}:${minutes}`
}

function handleMouseDown(event: MouseEvent) {
  if (event.button === 2) {
    event.preventDefault()
    showContextMenu(event)
  }
}

function handleTouchStart(event: TouchEvent) {
  if (event.touches.length === 1) {
    touchTimeout.value = window.setTimeout(() => {
      showContextMenu(event)
    }, 500)
  }
}

function handleTouchEnd() {
  if (touchTimeout.value) {
    clearTimeout(touchTimeout.value)
  }
}

function openFile() {
  if (fileUlr.value) {
    window.open(fileUlr.value, '_blank')
  }
}

function downloadFile() {
  if (props.message.file && fileUlr.value && fileName.value) {
    // const a = document.createElement('a')
    // a.setAttribute('download', '')
    // a.href = fileUlr.value
    // a.click()
    // const a = document.createElement('a')
    // a.href = fileUlr.value
    // a.setAttribute('download', '')
    // document.body.appendChild(a)
    // console.log('a', a)
    // a.click()
    // const a = document.createElement('a')
    // a.href = fileUlr.value
    // a.setAttribute('download', fileName.value)
    // document.body.appendChild(a)
    // a.click()
  }
}

async function deleteMessage() {
  const res = await chatmessageModel.deleteMessage.post(props.message.id)

  if (res?._code !== 0) {
    toast.add({ severity: 'error', summary: 'Удаление сообщения', detail: res?._message })
  }
}
</script>
