<template>
  <div class="core-layout-header w-full bg-white shadow-lg px-8 fixed z-10">
    <div class="container mx-auto">
      <Menubar
        class="bg-transparent border-none p-0 py-3"
        breakpoint="1023px"
        :pt="{
          menu: 'lg:flex lg:w-full',
          start: 'w-full h-[35px] lg:w-fit',
          submenu: 'w-full'
        }"
        :model="items"
      >
        <template #start>
          <router-link :to="{ name: mortgageRouteNames.list }">
            <img
              src="@/assets/inpocket-full-logo.svg"
              class="mr-6"
              width="150"
              height="35"
            />
          </router-link>
        </template>

        <template #item="{ item, props, hasSubmenu, root }">
          <TheHeaderItem
            :root="root"
            :item="item"
            :props="props"
            :has-submenu="hasSubmenu"
          />
        </template>
      </Menubar>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSalespointStore } from '@/store'
import { computed, reactive, watch } from 'vue'
import { MenuItem } from 'primevue/menuitem'
import { useRouter } from 'vue-router'
import Menubar from 'primevue/menubar'
import { mortgageRouteNames } from '@/pages/mortgage'
import { useSelectSalespointModel } from '@/features/salespoint'
import TheHeaderItem from './TheHeaderItem.vue'
import { useAuthModel } from '@/features/auth'
import { routesNames } from '@/app/router'
import { useChatmessageModel } from '@/entities/chatmessage'
import { storeToRefs } from 'pinia'

type CoreLayoutMenuItem = MenuItem & {
  labelClass?: string
  tooltip?: string
}

const router = useRouter()

const authModel = useAuthModel()

const salespointStore = useSalespointStore()

const { haveUnreadMessage, numberOfUnreadMessages } = storeToRefs(useChatmessageModel())

const { openModal: openSelectSalespointModal } = useSelectSalespointModel()

const initials = computed(() => {
  if (authModel.user?.last_name && authModel.user?.first_name) {
    return `${authModel.user?.last_name} ${authModel.user?.first_name[0]?.toLocaleUpperCase()}.`
  }

  return ''
})

const userMenuItem = reactive<CoreLayoutMenuItem>({
  label: initials.value,
  icon: 'pi pi-user',
  route: routesNames.user.base
  // params: {
  //   id: authModel.user?.id
  // }
  // command: () => userProfileModal.open(authModel.user as User, { modal: false })
})

const salespointMenuItem = reactive<CoreLayoutMenuItem>({
  label: salespointStore.salespoint?.title || '',
  icon: 'pi pi-shopping-bag',
  class: 'lg:max-w-[200px]',
  command: () => openSelectSalespointModal()
})

const logoutMenuItem = reactive<CoreLayoutMenuItem>({
  label: 'Выйти',
  icon: 'pi pi-sign-out',
  tooltip: 'Выйти',
  labelClass: 'lg:hidden',
  command: () => {
    authModel.logout().then(() => {
      router.push({ name: routesNames.account.login })
    })
  }
})

const reportsMenuItem = reactive({
  label: 'Отчеты',
  icon: 'pi pi-table',
  // route: ReportsRouteNames.base,
  visible: computed(
    () =>
      authModel.user?.raw_permissions?.includes('reports_get_own') ||
      authModel.user?.raw_permissions?.includes('reports_get_all')
  ),
  items: [
    {
      label: 'Статистика по заявкам',
      route: routesNames.reports.loan
    },
    {
      label: 'Статистика по точкам',
      route: routesNames.reports.stat,
      visible: computed(() => authModel.user?.raw_permissions?.includes('mortgage_get_bc'))
    }
  ]
})

const chatmessageMenuItemBadge = computed(() =>
  numberOfUnreadMessages.value ? numberOfUnreadMessages.value : null
)
const chatmessageMenuItem = reactive({
  label: 'Чат поддержки',
  icon: 'pi pi-envelope',
  route: routesNames.chatmessage.threads,
  badge: chatmessageMenuItemBadge
})

const domRfMenuItem = reactive({
  label: 'Дом.рф',
  icon: 'pi pi-credit-card',
  visible: computed(() => Boolean((salespointStore.salespoint as any)?.domrf_link)),
  items: [
    {
      label: 'Отправить заявку',
      route: 'Domrf'
    },
    {
      label: 'ЛК Дом.рф',
      command: () => {
        window.open(
          'https://id.domrfbank.ru/auth/realms/platform/protocol/openid-connect/auth?client_id=lk-agent&redirect_uri=https%3A%2F%2Fpartner.domrfbank.ru%2Flk-agent%2Fnews%2Fbd653622-0898-4622-a673-fb315741cc4d&state=82d71cc7-dcab-45ec-8b70-fb63fd179e4b&response_mode=fragment&response_type=code&scope=openid&nonce=30fa5e1c-f603-4826-a67a-0520d3f58815&code_challenge=WZxmBUgoKiZhIrevqgMl6xIv_OWUvYAWQAxkWDkxuFk&code_challenge_method=S256',
          '_blank'
        )
      }
    }
  ]
})
const domaInpocketMenuItem = reactive({
  label: 'Недвижимость',
  icon: 'pi pi-home',
  command: () => {
    window.open('https://doma.inpocket.ru/', '_blank')
  }
})

const items = reactive<CoreLayoutMenuItem[]>([
  {
    label: 'Создать заявку',
    icon: 'pi pi-plus',
    route: mortgageRouteNames.finder
  },
  {
    label: 'Список заявок',
    icon: 'pi pi-list',
    route: mortgageRouteNames.list
  },
  reportsMenuItem,
  chatmessageMenuItem,
  domRfMenuItem,
  {
    separator: true,
    class: 'w-full m-0 border-gray-300 lg:flex-grow lg:w-fit'
  },
  userMenuItem,
  salespointMenuItem,
  domaInpocketMenuItem,
  {
    separator: true,
    class: 'w-full m-0 border-gray-300 lg:w-fit'
  },
  logoutMenuItem
])

// const secondItems = reactive<CoreLayoutMenuItem[]>([userMenuItem, salespointMenuItem, logoutMenuItem])

watch(
  () => salespointStore.salespoint,
  () => {
    salespointMenuItem.label = salespointStore.salespoint?.title || ''
  }
)
</script>
