<template>
  <div class="flex flex-col gap-4">
    <Fieldset
      v-for="(child, i) in children"
      :key="i"
      :pt="{
        legend: 'p-2'
      }"
    >
      <template #legend>
        <div class="flex items-center gap-2">
          <div>{{ `Ребенок ${i + 1}` }}</div>
          <Button
            text
            security="secondary"
            icon="pi pi-minus"
            @click="removeChild(i)"
          />
        </div>
      </template>

      <ChildForm
        v-model:lastname="child.lastname"
        v-model:firstname="child.firstname"
        v-model:middlename="child.middlename"
        v-model:disability_date="child.disability_date"
        v-model:birthday="child.birthday"
        v-model:country="child.country"
        v-model:cohabitation="child.cohabitation"
      />
    </Fieldset>

    <Button
      label="Добавить ребенка"
      severity="secondary"
      icon="pi pi-plus"
      @click="addChild()"
    />

    <Message
      v-if="errors"
      severity="error"
      :pt="{
        wrapper: 'px-4 py-2'
      }"
      @close="emits('messageClose')"
    >
      {{ errors.join(', ') }}
    </Message>
  </div>
</template>

<script setup lang="ts">
import { reactive, watch, defineModel, toRaw, ref } from 'vue'
import { ChildForm } from './ChildForm'
import Fieldset from 'primevue/fieldset'
import Message from 'primevue/message'
import Button from 'primevue/button'

defineProps<{
  errors: string[] | null
}>()

const emits = defineEmits<{
  messageClose: []
}>()

const model = defineModel<Child[] | null>()

type Child = {
  lastname: string
  firstname: string
  middlename: string
  disability_date: string | null
  birthday: string | null
  country: string
  cohabitation: boolean
}

const children = reactive<Child[]>([])

if (model.value) {
  children.push(...toRaw(model.value))
}

const addChild = () => {
  children.push({
    lastname: '', // Все строки - пустые
    firstname: '',
    middlename: '',
    disability_date: null,
    birthday: null,
    country: 'RU',
    cohabitation: false // Boolean - false
  })
}

const removeChild = (index: number) => {
  children.splice(index, 1)
}

watch(children, children => (model.value = toRaw(children)))

// watch(
//   numberOfChild, // Следим за свойством numberOfChild
//   numberOfChild => {
//     console.log('numberOfChild', numberOfChild)
//     console.log('children', children.length)
//     // Приводим массив children к длине numberOfChild
//     if (numberOfChild > children.length) {
//       // Если число детей увеличилось, добавляем объектов
//       const diff = numberOfChild - children.length
//       for (let i = 0; i < diff; i++) {
//         children.push({
//           lastname: '', // Все строки - пустые
//           firstname: '',
//           middlename: '',
//           disability_date: null,
//           birthday: null,
//           country: 'RU',
//           cohabitation: false // Boolean - false
//         })
//       }
//     } else if (numberOfChild < children.length) {
//       // Если число детей уменьшилось, удаляем лишние объекты
//       const diff = children.length - numberOfChild
//       for (let i = 0; i < diff; i++) {
//         children.pop()
//       }
//     }
//   },
//   { immediate: true }
// )
</script>
