<template>
  <div class="flex flex-col">
    <label class="text-sm text-gray-600 mb-1"> {{ label }} {{ required && label ? '*' : '' }} </label>

    <slot
      name="field"
      :invalid="invalid"
    ></slot>

    <small class="text-red-600"> {{ error }} &nbsp;</small>
  </div>
</template>

<script setup lang="ts">
import { Control } from '@/shared/form'
import { computed } from 'vue'

const props = defineProps<{
  control?: Control<unknown>
  label?: string
  required?: boolean
  invalid?: boolean
  error?: string[] | string | null
}>()

const error = computed(() => {
  if (props.error !== undefined) {
    return props.error
  }

  return props.control?.touched ? props.control?.errors?.[0] : null
})

const invalid = computed(() => props.control?.touched ? props.control?.isInvalid : false)
</script>
