import { useMortgageTableModel } from '@/entities/mortgage'
import { useAuthModel } from '@/features/auth'
import { useProvide } from '@/shared'
import { watch } from 'vue'

export const useMortgageTablePageStore = useProvide('mortgage-table-page-store', () => {
  const tableModel = useMortgageTableModel('local')
  const authModel = useAuthModel()
  watch(() =>  authModel.user, user => {
    if (!user) {
      tableModel.drop()
    }
  })

  return { tableModel }
})
