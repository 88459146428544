<template>
  <div class="flex flex-col">
    <label
      class="text-sm text-gray-600 mb-1"
      v-bind="props"
    >
      {{ label }} {{ required && label ? '*' : '' }}
    </label>

    <slot></slot>

    <small class="text-red-600"> {{error}} &nbsp;</small>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: string
  label?: string
  error?: string | null
  required?: boolean
}>()
</script>
