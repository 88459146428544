<template>
  <div
    :class="[
      'relative flex flex-col bg-white w-[150px] h-[150px] p-2 border border-solid border-gray-300 rounded'
    ]"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      v-show="isHovered"
      :class="[
        'absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center',
        'transition duration-400',
        'hover:bg-gray-600 hover:opacity-95'
      ]"
    >
      <div
        class="w-full h-full flex justify-center items-center hover:bg-gray-700 transition duration-200"
        @click.stop="open"
      >
        <i class="pi pi-eye text-white"></i>
      </div>
      <div
        class="w-full h-full flex justify-center items-center hover:bg-gray-700 transition duration-200"
        @click.stop="emits('remove')"
      >
        <i class="pi pi-times-circle text-white"></i>
      </div>
    </div>

    <!-- <Button
      v-show="isHovered"
      text
      rounded
      class="absolute top-[-5px] right-[-5px] text-gray-900"
      severity="secondary"
      icon="pi pi-times-circle"
    /> -->

    <div class="text-xs break-words">
      {{ name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import { computed, ref } from 'vue'

const props = defineProps<{
  file: File
}>()

const emits = defineEmits<{
  remove: []
}>()

const isHovered = ref<boolean>(false)

const name = computed(() => {
  return shortenFileName(props.file.name)
})

function open() {
  window.open(URL.createObjectURL(props.file), '_blank')
}

function shortenFileName(fileName: string, maxLength = 30) {
  // Находим индекс последней точки, чтобы разделить название и расширение.
  const lastDotIndex = fileName.lastIndexOf('.')

  // Извлекаем название файла и его расширение.
  const name = fileName.substring(0, lastDotIndex)
  const extension = fileName.substring(lastDotIndex)

  // Если длина названия превышает максимальную длину, обрезаем и добавляем троеточие.
  const shortName = name.length > maxLength ? name.substring(0, maxLength) + '... ' : name

  // Возвращаем итоговую строку.
  return shortName + extension
}
</script>
