import { computed, reactive, Ref, ref, watch } from 'vue'
import { Params, Control, Validator } from '../types'

export const useControl = <T>(params?: Params<T>): Control<T> => {
  const value = ref<T | null>(null) as Ref<T | null>
  const errors = ref<string[]>([])
  const dirty = ref<boolean>(false)
  const disabled = ref<boolean>(false)
  const touched = ref<boolean>(false)
  const isValid = computed(() => errors.value.length === 0)
  const isInvalid = computed(() => !isValid.value)
  const validators = ref<Validator[]>([])

  setParamsValue()
  validate()

  watch(value, () => {
    setDirty()
    validate()
  })

  function setParamsValue() {
    if (params) {
      if (Object.hasOwn(params, 'value')) {
        value.value = params.value as T | null
      }

      if (params?.validators) {
        addValidators(params.validators)
      }
    }
  }

  function reset() {
    value.value = null
    dirty.value = false
    touched.value = false
    disabled.value = false
    errors.value = []
    validators.value = []
    setParamsValue()
    validate()
  }

  function setDirty() {
    dirty.value = true
  }

  function setTouched() {
    touched.value = true
  }

  function setValue(newValue: T | null) {
    value.value = newValue
  }

  function setErrors(newErrors: string | string[]) {
    if (Array.isArray(newErrors)) {
      errors.value.push(...newErrors)
    } else {
      errors.value.push(newErrors)
    }
  }

  function addValidators(newValidators: Validator | Validator[]) {
    if (Array.isArray(newValidators)) {
      validators.value.push(...newValidators)
    } else {
      validators.value.push(newValidators)
    }

    validate()
  }

  function removeValidators() {
    validators.value = []
  }

  function validate() {
    errors.value = []

    validators.value.forEach(validator => {
      const error = validator(value.value)
      if (typeof error === 'string') {
        errors.value?.push(error)
      }
    })

    return errors.value.length > 0 ? false : true
  }

  function enable() {
    disabled.value = false
  }

  function disable() {
    disabled.value = true
  }

  return reactive({
    value,
    errors,
    dirty,
    disabled,
    touched,
    isValid,
    isInvalid,

    reset,
    enable,
    disable,
    setDirty,
    validate,
    setTouched,
    setValue,
    setErrors,
    addValidators,
    removeValidators
  })
}
