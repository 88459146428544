import { DictList } from '@/shared'

export const MORTGAGE_STAGE_LABELS = Object.freeze({
  app_create: 'Создана',
  app_wait: 'Ожидание',
  app_ending: 'Предварительно одобрено',
  app_issued: 'Одобрено',
  app_cancel: 'Отменена',
  app_complete_decline: 'Отказ',
  app_complete_accept: 'Предварительно одобрено'
})

export const MORTGAGE_STAGE_LABELS_DICT: Readonly<DictList> = [
  {
    code: 'app_create',
    title: 'Создана'
  },
  {
    code: 'app_wait',
    title: 'Ожидание'
  },
  {
    code: 'app_ending',
    title: 'Предварительно одобрено'
  },
  {
    code: 'app_issued',
    title: 'Одобрено'
  },
  {
    code: 'app_cancel',
    title: 'Отменена'
  },
  {
    code: 'app_complete_decline',
    title: 'Отказ'
  },
  {
    code: 'app_complete_accept',
    title: 'Предварительно одобрено'
  }
]
