<template>
  <BaseContent>
    <DataTable
      v-model:rows="pageSize"
      :first="startIndex"
      :value="mortgageModels"
      paginator
      lazy
      scrollable
      size="small"
      dataKey="id"
      filterDisplay="row"
      :total-records="totalSize"
      :loading="pending"
      :rowsPerPageOptions="[5, 10, 20]"
      :pt="{
        column: {
          filterMenuButton: 'hidden'
        }
      }"
      @page="onPage($event)"
    >
      <Column
        field="id"
        header="Номер заявки"
      >
        <template #body="{ data }">
          <div class="flex flex-col">
            <router-link
              v-slot="{ href, navigate }"
              :to="{
                name: mortgageRouteNames.app,
                params: { id: data.mortgage.id }
              }"
              custom
            >
              <a
                class="w-fit no-underline"
                :href="href"
                @click="navigate"
                >{{ data.mortgage.id }}</a
              >
            </router-link>
          </div>
        </template>

        <template #filter>
          <InputNumber
            v-model="filter.id"
            :use-grouping="false"
            @blur="onBlur"
            @keydown.enter="onBlur"
          />
        </template>
      </Column>

      <Column header="Клиент">
        <template #body="{ data }">
          <div class="text-gray-900">{{ getMainCustomerFio(data.mortgage) }}</div>

          <span
            v-tooltip.bottom="'Автор заявки'"
            class="text-xs text-gray-500"
          >
            {{ getUserFio(data.user) }}
          </span>
        </template>

        <template #filter>
          <InputText
            v-model="filter.last_name"
            @blur="onBlur"
            @keydown.enter="onBlur"
          />
        </template>
      </Column>

      <Column header="Торговая точка">
        <template #body="{ data }">
          <div class="text-gray-700">{{ data.salespoint?.title }}</div>
        </template>

        <template #filter>
          <InputSalespoint
            v-model="filter.salespoint_id"
            @blur="onBlur"
            @keydown.enter="onBlur"
          />
        </template>
      </Column>

      <Column header="Статус">
        <template #body="{ data }">
          <MortgageStatus :mortgage="data.mortgage" />
        </template>

        <!-- <template #filter>
          <Dropdown
            v-model="filter.status"
            show-clear
            option-label="title"
            option-value="code"
            :options="MORTGAGE_STAGE_DICT_LIST"
            @blur="onBlur"
            @change="onBlur"
          />
        </template> -->
      </Column>

      <Column header="Дата">
        <template #body="{ data }">
          {{ new Date(data.mortgage.created_at).toLocaleDateString() }}
        </template>

        <template #filter>
          <InputPeriod
            v-model:date1="filter.date1"
            v-model:date2="filter.date2"
            @blur="onBlur"
            @keydown.enter="onBlur"
          />
        </template>
      </Column>

      <Column header="Сумма">
        <template #body="{ data }">
          <span class="whitespace-nowrap"> {{ Math.round(data.mortgage.credit_amount).toLocaleString() }}₽ </span>
        </template>

        <template #filter>
          <InputRub
            v-if="filter"
            v-model="filter.credit_amount"
            @blur="onBlur"
            @keydown.enter="onBlur"
          />
        </template>
      </Column>

      <Column
        header="Банк"
        style="min-width: 60px"
      >
        <template #body="{ data }">
          <BaseBankIcon
            v-if="data.bankName"
            :bankName="data.bankName"
          />
        </template>
      </Column>

      <slot name="columns"></slot>
    </DataTable>
  </BaseContent>
</template>

<script setup lang="ts">
import { nextTick } from 'vue'
import { getUserFio } from '@/entities/user'
import { mortgageRouteNames } from '@/pages/mortgage'
import { BaseBankIcon, BaseContent, InputSalespoint, InputPeriod, InputRub } from '@/shared'
import { MortgageStatus, MortgageTableFilter, getMainCustomerFio, useMortgageTableModel } from '@/entities/mortgage'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import DataTable, { DataTablePageEvent } from 'primevue/datatable'

const { filter, mortgageModels, getMortgages, pageNumber, pageSize, totalSize, pending, startIndex } =
  useMortgageTableModel('provide')

function onPage(event: DataTablePageEvent) {
  if (pageNumber.value !== event.page) {
    pageNumber.value = event.page
  }
}

let oldFilter: MortgageTableFilter = { ...filter }
function onBlur() {
  nextTick(() => {
    const _filter = JSON.stringify(filter)
    const _oldFilter = JSON.stringify(oldFilter)
    if (_filter !== _oldFilter) {
      oldFilter = { ...filter }
      getMortgages()
    }
  })
}
</script>
