<template>
  <Tag
    class="text-xs"
    :severity="severity"
    >{{ status }}</Tag
  >
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Tag from 'primevue/tag'
import { MORTGAGE_STAGE_LABELS } from '../../const'
import { Mortgage } from '../../types'

const props = defineProps<{
  mortgage: Mortgage
}>()

const status = computed(() => {
  const mortgageStatus = props.mortgage.stages.current
  const bankAppStatus = props.mortgage.bank_app_status?.status
  if (mortgageStatus === 'app_complete_decline') {
    return bankAppStatus === 'bank_note' ? 'Возврат' : 'Отказ'
  }

  return MORTGAGE_STAGE_LABELS[mortgageStatus]
})

const severity = computed(() => {
  const mortgageStatus = props.mortgage.stages.current
  if (mortgageStatus === 'app_cancel') {
    return 'danger'
  }

  if (mortgageStatus === 'app_ending' || mortgageStatus === 'app_issued' || mortgageStatus === 'app_complete_accept') {
    return 'success'
  }

  if (mortgageStatus === 'app_create' || mortgageStatus === 'app_wait') {
    return 'primary'
  }

  if (mortgageStatus === 'app_complete_decline') {
    if (props.mortgage.bank_app_status?.status === 'bank_note') {
      return 'warning'
    } else {
      return 'danger'
    }
  }

  return ''
})
</script>
