import { FieldContext, useField } from 'vee-validate'
import { Ref, computed } from 'vue'

export const useBaseInput = <T>(modelValue: Ref<T>, emits: any, name?: string) => {
  let field: FieldContext<T> | null = null
  if (name) {
    field = useField<T>(name)
  }

  const errorMessage = computed(() => field?.errorMessage.value)

  const value = computed<T>({
    get() {
      return (modelValue.value || field?.value.value) as T
    },
    set(value: T) {
      modelValue.value = value
      field?.setValue(value)
    }
  })

  const onBlur = (event: Event) => {
    field?.handleBlur(event)
    // Иначе не валидирует поле при этом эвенте
    field?.validate() 
    emits('blur', event)
  }
  const onChange = (event: Event) => {
    field?.handleChange(event)
    emits('change', event)
  }
  const onFocus = (event: Event) => {
    emits('focus', event)
  }

  return {
    field,
    value,
    errorMessage,
    onBlur,
    onFocus,
    onChange
  }
}
