<template>
  <Button
    v-tooltip="'Объединение в pdf-файл'"
    text
    rounded
    icon="pi pi-file-pdf"
    :icon-class="iconClass"
    severity="secondary"
    @click="openMergeModal"
  />
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import { useDialog } from 'primevue/usedialog'
import UtilsPdfMergeModal from './UtilsPdfMergeModal.vue'

const props = defineProps<{
  iconClass?: string
}>()

const emits = defineEmits<{
  merge: [File]
}>()

const dialog = useDialog()

function openMergeModal() {
  dialog.open(UtilsPdfMergeModal, {
    props: {
      modal: true,
      showHeader: false,
      contentClass: 'p-0 bg-transparent',
      pt: {
        root: 'border-0 sm:w-[548px]'
      }
    },
    onClose: event => {
      if (event?.type === 'config-close') {
        const mergedFile = event?.data as File | null
        if (mergedFile) {
          emits('merge', mergedFile)
        }
      }
    }
  })
}
</script>
