import { DictList, useApiFetch } from '@/shared'
import { useMemoize } from '@vueuse/core'

export type GeneralDictNames =
  | 'AddressStatus'
  | 'MaritalStatus'
  | 'ContactRelation'
  | 'Education'
  | 'EmploymentType'
  | 'EmployerActivityType'
  | 'EmployerCompanyStatus'
  | 'EmploymentPositionType'
  | 'ProductCategory'
  | 'NotificationType'
  | 'Country'
  | 'AdditionalIncomeType'
  | 'CancelReason'

const baseUrl = 'generalapp/'

export const useGeneralGetDict = useMemoize(async (dictName: GeneralDictNames) => {
  const { post } = useApiFetch<DictList>(baseUrl + `dict/${dictName}/get_list`, {}, 'json')
  const res = await post()
  return res?._data
})

/** Получение генерал справочника */
export const useGetGeneralDict = (dictName: GeneralDictNames) =>
  useApiFetch<DictList>(baseUrl + `dict/${dictName}/get_list`, {}, 'json')
