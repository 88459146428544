<template>
  <AutoComplete
    v-model="fio"
    option-label="name"
    input-class="w-full"
    v-bind="{
      inputId,
      invalid,
      disabled,
      placeholder,
      readonly,
      suggestions
    }"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @change="emits('change', $event)"
    @complete="search"
    @item-select="onItemSelect"
  />
</template>

<script setup lang="ts">
import { defineModel, ref } from 'vue'
import AutoComplete, {
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
  AutoCompleteItemSelectEvent
} from 'primevue/autocomplete'
import { Sex } from '@/shared'
import { watchIgnorable } from '@vueuse/core'
import { Individual, useCommondictsIndividual } from '@/shared'

type NormalizeIndividual = {
  name: string
  sex: Sex | null
}

const props = defineProps<{
  invalid?: boolean
  disabled?: boolean
  placeholder?: string
  readonly?: boolean
  inputId?: string
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
  change: [AutoCompleteChangeEvent]
  sex: [Sex]
}>()

const last_name = defineModel<string | null>('last_name')
const first_name = defineModel<string | null>('first_name')
const middle_name = defineModel<string | null>('middle_name')

const suggestions = ref<NormalizeIndividual[]>()
const fio = ref<string | null>(`${last_name.value || ''} ${first_name.value || ''} ${middle_name.value || ''}`.trim())

const { post: individualPost, abort: individualAbort } = useCommondictsIndividual()

const { ignoreUpdates: IgnoreUpdatesFio } = watchIgnorable(fio, fio => {
  ignoreUpdatesLastName
  ignoreUpdatesFirstName
  ignoreUpdatesMiddleName
  if (fio && typeof fio === 'string') {
    const [lastName, firstName, middleName] = fio?.replace(/\s+/g, ' ').split(' ') || ''
    ignoreUpdatesLastName(() => {
      last_name.value = lastName || ''
    })
    ignoreUpdatesFirstName(() => {
      first_name.value = firstName || ''
    })
    ignoreUpdatesMiddleName(() => {
      middle_name.value = middleName || ''
    })
  } else {
    ignoreUpdatesLastName(() => (last_name.value = null))
    ignoreUpdatesFirstName(() => (first_name.value = null))
    ignoreUpdatesMiddleName(() => (middle_name.value = null))
  }
})

// const { ignoreUpdates: IgnoreUpdatesLastFirstMiddleName } = watchIgnorable(
//   [last_name, first_name, middle_name],
//   ([last_name, first_name, middle_name]) => {
//     IgnoreUpdatesFio(() => {
//       console.log('IgnoreUpdatesFio')
//       fio.value = `${last_name || ''} ${first_name || ''} ${middle_name || ''}`.trim()
//     })
//   },
//   { immediate: true }
// )

const { ignoreUpdates: ignoreUpdatesLastName } = watchIgnorable(last_name, last_name => {
  IgnoreUpdatesFio(() => {
    fio.value = `${last_name || ''} ${first_name.value || ''} ${middle_name.value || ''}`.trim()
  })
})

const { ignoreUpdates: ignoreUpdatesFirstName } = watchIgnorable(first_name, first_name => {
  IgnoreUpdatesFio(() => {
    fio.value = `${last_name.value || ''} ${first_name || ''} ${middle_name.value || ''}`.trim()
  })
})

const { ignoreUpdates: ignoreUpdatesMiddleName } = watchIgnorable(middle_name, middle_name => {
  IgnoreUpdatesFio(() => {
    fio.value = `${last_name.value || ''} ${first_name.value || ''} ${middle_name || ''}`.trim()
  })
})

function onItemSelect(event: AutoCompleteItemSelectEvent) {
  const selectedItem = event.value as NormalizeIndividual
  fio.value = selectedItem.name
  if (selectedItem.sex) {
    emits('sex', selectedItem.sex)
  }
}

async function getIndividual(query: string) {
  const normalizeIndividual = (individual: Individual) => {
    return {
      name: `${individual.last_name || ''} ${individual.first_name || ''} ${individual.middle_name || ''}`.trim(),
      sex: individual.sex
    } as NormalizeIndividual
  }

  individualAbort()

  const res = await individualPost({ _data: { name: query } })

  if (res?._code === 0 && res._data) {
    return [...res._data.EXTERNAL, ...res._data.INTERNAL].map(opt => normalizeIndividual(opt))
  }

  return []
}

async function search(event: AutoCompleteCompleteEvent) {
  suggestions.value = await getIndividual(event.query)
}
</script>
