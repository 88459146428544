import { ComponentPublicInstance, computed, nextTick, ref, toRefs } from 'vue'
import { Nullable, requiredValidator, useControl, useProvide } from '@/shared'
import { useForm } from '@/shared'
import useMortgageCustomerDeleteModel from './mortgage-customer-delete'
import useMortgageCustomerUpdateModel from './mortgage-customer-update'
import useMortgageCustomerScansModel from './mortgage-customer-scans'
import { useMortgageModel } from '../mortgage-model'
import { CustomerFields } from '@/entities/customer'

type MortgageCustomerFormFields = Partial<Nullable<CustomerFields>>

/**
 ** Фабричная функция, которая создает модель для работы с кастомером
 *   - Имеется useProvide
 */
export const useMortgageCustomerModel = (customer_id?: string) => {
  return useProvide('mortgageCustomer', () => {
    const mortgageModel = useMortgageModel()
    const { mortgage } = toRefs(mortgageModel)

    const customerId = ref<string | null>(customer_id || null)
    const customer = computed(() => {
      if (mortgage.value) {
        if (mortgage.value.customer_applications.main_customer.id === customerId.value) {
          return mortgage.value.customer_applications.main_customer
        } else {
          const customer = mortgage.value?.customer_applications?.customers?.find(
            customer => customer.id === customerId.value
          )
          return customer || null
        }
      }

      return null
    })

    const customersRoleDict = [
      {
        code: 'COBORROWER',
        title: 'Созаёмщик'
      },
      {
        code: 'GUARANTOR',
        title: 'Поручитель'
      }
    ]

    const _customerRole = ref<string | null>(null)
    const customerRole = computed({
      get() {
        return customerId.value && mortgage.value?.roles_coborrower?.[customerId.value]
          ? mortgage.value.roles_coborrower[customerId.value]
          : _customerRole.value
      },
      set(value: string | null) {
        _customerRole.value = value
      }
    })

    const sourceDownPayment = useControl<string | null>('source_down_payment', {
      initialValue: null,
      validators: [requiredValidator]
    })

    const sourceDownPaymentObject = useControl<string | null>('source_down_payment_object', {
      initialValue: null,
      validators: [requiredValidator]
    })

    const landAmount = useControl<number | null>('land_amount', {
      initialValue: null,
      validators: [requiredValidator]
    })

    // const kinshipCoborrower = ref<string | null>(null)
    const kinshipCoborrower = useControl<string | null>('kinshipCoborrower2', {
      initialValue: null,
      validators: [requiredValidator]
    })

    const gazprombankm_outstandingCreditDebt = useControl<number | null>(
      'gazprombankm_outstandingCreditDebt',
      { initialValue: null, validators: [requiredValidator] }
    )

    if (customer.value && customerId.value) {
      if (mortgage.value?.kinship_coborrower) {
        kinshipCoborrower.setValue(mortgage.value.kinship_coborrower?.[customerId.value])
      }

      if (mortgage.value?.gazprombankm_outstandingCreditDebt) {
        gazprombankm_outstandingCreditDebt.setValue(mortgage.value?.gazprombankm_outstandingCreditDebt)
      }

      if (mortgage.value?.land_amount) {
        landAmount.setValue(mortgage.value.land_amount)
      }

      if (mortgage.value?.source_down_payment) {
        sourceDownPayment.setValue(mortgage.value?.source_down_payment)
      }

      if (mortgage.value?.source_down_payment_object) {
        sourceDownPaymentObject.setValue(mortgage.value?.source_down_payment_object)
      }
    }

    // const kinshipCoborrower = computed({
    //   get() {
    //     return customerId.value && mortgage.value?.kinship_coborrower?.[customerId.value]
    //       ? mortgage.value.kinship_coborrower[customerId.value]
    //       : _kinshipCoborrower.value
    //   },
    //   set(value: string | null) {
    //     _kinshipCoborrower.value = value
    //   }
    // })

    const customerType = computed(() => {
      if (mortgage.value) {
        if (mortgage.value.customer_applications.main_customer.id === customerId.value) {
          return 'main'
        } else {
          return 'customer'
        }
      } else {
        return 'main'
      }
    })

    /** Форма полей анкеты */
    const form = useForm<MortgageCustomerFormFields>()

    const status = computed<{
      completion: number
      message: string | null
    } | null>(() => {
      if (!customer.value) {
        return {
          message: 'Заполните анкету',
          completion: 0
        }
      }

      if (doctypes.value && scans.value) {
        const uploadedScansQuantity = doctypes.value.reduce((acc, doc) => {
          if (scans.value?.find(scan => scan.scan_type === doc.code)) {
            acc += 1
          }

          return acc
        }, 0)
        const completion = (uploadedScansQuantity * 100) / doctypes.value.length
        let message: string | null = null
        if (completion !== 100) {
          const notFilledScans: string[] = []
          doctypes.value.forEach(doc => {
            if (!scans.value?.find(scan => scan.scan_type === doc.code)) {
              notFilledScans.push(doc.title)
            }
          })

          message = 'Не заполнены сканы: ' + notFilledScans.join(', ')
        }

        return {
          message,
          completion: 50 + Math.round(completion / 2)
        }
      }

      return {
        message: null,
        completion: 0
      }
    })

    const container = ref<ComponentPublicInstance>()
    function scrollToFirstError() {
      if (container.value) {
        nextTick(() => {
          const invalidField = container.value?.$el.querySelector('.p-invalid')
          invalidField?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      }
    }

    const panelCollapsed = ref<boolean>(true)
    function togglePanel() {
      panelCollapsed.value = !panelCollapsed.value
    }

    function openPanel() {
      panelCollapsed.value = false
    }

    function closePanel() {
      panelCollapsed.value = true
    }

    function getCustomerFio() {
      const { last_name, first_name, middle_name } = form.values.value
      return `${last_name || ''}  ${first_name || ''} ${middle_name || ''}`.trim()
    }

    const { doctypes, scans, documentTypeDict, getScans, getDoctypes, getDocumentTypeDict } =
      useMortgageCustomerScansModel(customer)

    const {
      update: updateCustomer,
      pending: updateCustomerPending,
      disabled: updateCustomerDisabled,
      disabledReason: updateCustomerDisabledReason,
      response: updateCustomerResponse
    } = useMortgageCustomerUpdateModel(
      customerId,
      customer,
      customerType,
      customerRole,
      kinshipCoborrower,
      landAmount,
      sourceDownPayment,
      sourceDownPaymentObject,
      gazprombankm_outstandingCreditDebt,
      form,
      container,
      getCustomerFio,
      scrollToFirstError
    )

    const {
      deleteCustomer,
      disabled: deleteCustomerDisabled,
      disabledReason: deleteCustomerDisabledReason,
      pending: deleteCustomerPending
    } = useMortgageCustomerDeleteModel(
      customerId,
      customer,
      customerType,
      form,
      doctypes,
      scans,
      getCustomerFio
    )

    return {
      mortgageModel,
      landAmount,
      gazprombankm_outstandingCreditDebt,
      sourceDownPayment,
      sourceDownPaymentObject,

      // customer
      customerId,
      customer,
      customerType,
      getCustomerFio,
      customerRole,
      customersRoleDict,
      kinshipCoborrower,

      // form
      form,

      // scans
      scans,
      doctypes,
      getScans,
      getDoctypes,
      documentTypeDict,
      getDocumentTypeDict,

      // update
      updateCustomer,
      updateCustomerPending,
      updateCustomerDisabled,
      updateCustomerDisabledReason,
      updateCustomerResponse,

      // delete
      deleteCustomer,
      deleteCustomerDisabled,
      deleteCustomerDisabledReason,
      deleteCustomerPending,

      //other
      status,
      container,
      togglePanel,
      openPanel,
      closePanel,
      panelCollapsed,
      scrollToFirstError
    }
  })()
}
