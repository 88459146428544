<template>
  <InputMask
    v-model="localDate"
    v-bind="{
      id,
      disabled,
      placeholder,
      invalid,
      readonly
    }"
    mask="99.99.9999"
    slotChar="дд.мм.гггг"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
  />
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue'
import { InputProps, InputEmits } from '@/shared';
import InputMask from 'primevue/inputmask'

const props = defineProps<InputProps>()
const emits = defineEmits<InputEmits>()

const model = defineModel<string | null>()
const localDate = ref<string | null>()

watch(
  model,
  date => {
    if (date) {
      const [year, month, day] = date.split('-')
      localDate.value = `${day}.${month}.${year}`
    } else {
      localDate.value = ''
    }
  },
  { immediate: true }
)

// При изменении внутреннего значения изменяем внешнее
watch(localDate, date => {
  if (date) {
    const [day, month, year] = date.split('.')
    model.value = year + '-' + month + '-' + day
  } else {
    model.value = ''
  }
})
</script>
