<template>
  <AutoComplete
    v-model="model"
    v-bind="{
      disabled,
      placeholder
    }"
    forceSelection
    input-class="w-full"
    option-label="address_text"
    :class="{ 'pointer-events-none': readonly }"
    :input-id="id"
    :invalid="invalid"
    :suggestions="options"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
  />
</template>

<script setup lang="ts">
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
import { ref, defineModel } from 'vue'
import { InputProps, InputEmits, Address, useDadataRepository } from '@/shared';

const props = defineProps<InputProps>()
const emits = defineEmits<InputEmits>()
const model = defineModel<string | Address | null>()

const options = ref<Address[]>([])
const { getAddress } = useDadataRepository()

async function search(event: AutoCompleteCompleteEvent) {
  options.value = await getAddress(event.query)
}
</script>

