import { BankName, DictList, useApiFetch } from '@/shared'
import { useMemoize } from '@vueuse/core'

export type CustomerDictNames =
  | 'DocumentType'
  | 'EmploymentPositionType'
  | 'NatureOfWork'
  | 'FieldOfActivityInCompany'
  | 'LegalForm'
  | 'EmploymentType'

import { DadataAddress, Sex } from '@/shared'

export type Individual = {
  last_name: string | null
  first_name: string | null
  middle_name: string | null
  sex: Sex | null
}

export type IndividualResponse = {
  INTERNAL: Individual[]
  EXTERNAL: Individual[]
}

export type CommondictsCustomerDictName =
  | 'DocumentType'
  | 'EmploymentPositionType'
  | 'NatureOfWork'
  | 'FieldOfActivityInCompany'
  | 'LegalForm'
  | 'EmploymentType'

export type FmsGetResponse = {
  INTERNAL: string[]
  EXTERNAL: string[]
}

export interface Ifns {
  title: string
  opf: {
    full: string
    short: string
  }
  okved: string
  okveds: string
  inn: string
  kpp: string
  address: {
    data: DadataAddress
    invalidity: null
    unrestricted_value: string
    value: string
  } | null
  phone: string | null
  head_position: string
  head_fio: string
  registration_date: number
}

const baseUrl = 'commondicts/'

/** Получить справочник для кастомера */
export const useCommondictsGetCustomerDict = useMemoize(async (dictName: CustomerDictNames) => {
  const { post } = useApiFetch<DictList>(baseUrl + `customer/${dictName}`, {}, 'json')
  const res = await post()
  return res?._data
})

/** Получить информацию об организации */
export const useCommondictsIfns = () =>
  useApiFetch<{ _data: { inn?: string; title?: string } }, Ifns[]>(baseUrl + 'ifns', {}, 'json')

/** Получить место выдачи паспорта по коду подразделения */
export const useCommondictsFms = () =>
  useApiFetch<{ _data: { code: string } }, FmsGetResponse>(baseUrl + 'passport/fms/get', {}, 'json')

/** Подсказки для ФИО */
export const useCommondictsIndividual = () =>
  useApiFetch<{ _data: { name: string } }, IndividualResponse>(baseUrl + 'individual', {}, 'json')

/** Справочник по стадиям банка */
export const useCommondictsGetBankStageDict = useMemoize(async (bankName: BankName) => {
  const { post } = useApiFetch<DictList>(baseUrl + `stages/${bankName}`, {}, 'json')
  const res = await post()
  return res?._data
})

/** Получение справочников по кастомеру */
export const useGetCommondictsCustomerDicts = (dictName: CommondictsCustomerDictName) =>
  useApiFetch<DictList>(baseUrl + `customer/${dictName}`, {}, 'json')
