import { TObject } from "@/shared"

const loginError: TObject<string, string> = Object.freeze({
  101: 'Неверный логин или пароль',
  102: 'Отключённый аккаунт',
  103: 'Забаненный аккаунт'
})

export const loginResponseAdapter = (code?: number, message?: string | null) => {
  if (code) {
    return loginError[code] || message
  }

  return message
}