import { TObject, dadataAddressNormalize } from "@/shared"

export interface DadataAddress {
  postal_code: string
  region_fias_id: string
  region_kladr_id: string
  region_with_type: string
  region_type: string
  region_type_full: string
  region: string
  area_fias_id: string
  area_kladr_id: string
  area_with_type: string
  area_type: string
  area_type_full: string
  area: string
  sub_area: string
  sub_area_fias_id: string
  sub_area_kladr_id: string
  sub_area_type: string
  sub_area_type_full: string
  sub_area_with_type: string
  city_fias_id: string
  city_kladr_id: string
  city_with_type: string
  city_type: string
  city_type_full: string
  city: string
  city_district_fias_id: string
  city_district_kladr_id: string
  city_district_with_type: string
  city_district_type: string
  city_district_type_full: string
  city_district: string
  settlement_fias_id: string
  settlement_kladr_id: string
  settlement_with_type: string
  settlement_type: string
  settlement_type_full: string
  settlement: string
  planning_structure_fias_id: string
  planning_structure_kladr_id: string
  planning_structure_with_type: string
  planning_structure_type: string
  planning_structure_type_full: string
  planning_structure: string
  street_fias_id: string
  street_kladr_id: string
  street_with_type: string
  street_type: string
  street_type_full: string
  street: string
  house_fias_id: string
  house_kladr_id: string
  house_type: string
  house: string
  block: string
  block_type: string
  building_type: string
  building: string
  fias_id: string
  fias_code: string

  fias_level: number
  //   0 — страна
  //   1 — регион
  //   3 — район
  //   4 — город
  //   5 — район города
  //   6 — населенный пункт
  //   7 — улица
  //   8 — дом
  //   9 — квартира
  //  65 — планировочная структура
  //  75 — земельный участок
  //  -1 — иностранный или пустой

  fias_actuality_state: string
  kladr_id: string
  capital_marker: string
  okato: string
  oktmo: string
  cadastral_number: string
  tax_office: string
  tax_office_legal: string
  history_values: string
  source: string
  stead: string
  stead_type: string
  qc: string
  flat: string
  flat_type: string
}

export interface DadataAddressSuggestions {
  data: DadataAddress
  unrestricted_value: string
  value: string
}

export interface DadataAddressResponse {
  suggestions: DadataAddressSuggestions[]
}


export const useDadataRepository = () => {
  const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs'

  async function getAddress(query: string, params?: TObject<string, any>) {
    const body = JSON.stringify({ query, ...params })
    const res = await fetch(url + '/suggest/address/', {
      body,
      method: 'POST',
      headers: {
        authorization: 'Token 24cf832c4456ea8fdb46ffeccf9bfda840850eb1',
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; text/html; image/png'
      }
    })

    const data = (await res.json()) as DadataAddressResponse
    return data.suggestions.map(suggestion => dadataAddressNormalize(suggestion.unrestricted_value, suggestion.data))
  }

  return {
    getAddress,
  }
}
