import { ApiFetchOptions, useApiFetch } from "@/shared"

export type User = {
  id: number
  first_name: string
  last_name: string
  is_active: true
  deleted_at: string | null
  email: string
  middle_name: string
  dateofbirth: string
  phone: number
  phone_confirmed: boolean
  status: string
  passport_serial: number
  passport_number: number
  passport_date: Date
  passport_issuing: string | number
  passport_division_code: string | number
  address_country: string | number
  address_postcode: string | number
  address_region: string | number
  address_city: string | number
  address_street: string | number
  address_house: string | number
  address_flat: string | number
  ILBS_login: string | number
  cert_serial: string | number
  groups_ids: []
  permissions: string[]
  title: string
  raw_permissions?: string[]
  brokerclient_staff: number[]
  salespoint_staff: number[]
  is_filled_profile: boolean
}

const baseUrl = 'user/'

/** Получить юзера по id */
export const useGetUser = () => useApiFetch<{ _data: { id: number } }, User>(baseUrl + 'get', {}, 'json')

/** Получить список юзеров */
export const useGetUserList = () => useApiFetch<ApiFetchOptions, User[]>(baseUrl + 'get_list', {}, 'json')
