import { defineStore } from 'pinia'
import { Salespoint, useGetSalespoint } from 'simpleloan_api'
import { ref, watch } from 'vue'

const localStorageName = 'salespoint_id_mortgage'

export const useSalespointModel = defineStore('salespoint-model', () => {
  const salespoint = ref<Salespoint | null>(null)

  const get = useGetSalespoint()

  watch(get.response, res => {
    if (res?._code === 0 && res._data) {
      salespoint.value = res._data
    }
  })

  watch(salespoint, salespoint => {
    if (salespoint) {
      localStorage.setItem(localStorageName, salespoint.id.toString())
    } else {
      localStorage.removeItem(localStorageName)
    }
  })

  async function getFromLS() {
    const id = Number(localStorage.getItem(localStorageName))

    if (!id) return null

    const res = await get.post({ body: { id } })

    if (res?._code === 0 && res._data) {
      salespoint.value = res._data
    }

    return res
  }

  return {
    salespoint,
    getFromLS,
    get
  }
})
