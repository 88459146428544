<template>
  <img
    v-if="full"
    :src="require(`@/assets/bank-icons/${props.bankName}-icon-full.svg`)"
  />

  <figure v-else>
    <img
      v-tooltip.bottom="bankName"
      height="32"
      :src="require(`@/assets/bank-icons/${props.bankName}-icon.svg`)"
    />
  </figure>
</template>

<script setup lang="ts">
import { BankName } from '@/shared'

const props = defineProps<{
  bankName: BankName
  full?: boolean
}>()
</script>

<style scoped>
figure {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  animation: initial;
  position: relative;
  overflow: hidden;
  border: none;
  margin: 0px;
}
</style>
