<template>
  <BaseContent>
    <div class="text-gray-900 text-xl font-medium mb-4">{{ fio }}</div>

    <div class="bg-gray-100 p-4 rounded-2xl">
      <div class="col-span-full ps-0 p-2 flex">
        <div class="w-[250px] text-gray-500 font-medium">Email</div>
        <div class="text-gray-900">{{ user.email }}</div>
      </div>

      <div class="col-span-full ps-0 p-2 flex">
        <div class="w-[250px] text-gray-500 font-medium">Телефон</div>
        <div class="text-gray-900">+7{{ user.phone }}</div>
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { BaseContent, User } from '@/shared'
import { computed } from 'vue'

const props = defineProps<{
  user: User
}>()

const fio = computed(() => `${props.user.last_name} ${props.user.first_name} ${props.user.middle_name || ''}`)
</script>
