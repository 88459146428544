<template>
  <div class="grid grid-cols-12 gap-x-4">
    <BaseInputForm
      class="col-span-full"
      label="ФИО ребенка"
    >
      <InputFio
        v-model:last_name="lastname"
        v-model:first_name="firstname"
        v-model:middle_name="middlename"
      />
    </BaseInputForm>

    <BaseInputForm
      class="col-span-4"
      label="дата рождения"
    >
      <InputDate v-model="birthday" />
    </BaseInputForm>

    <BaseInputForm
      class="col-span-4"
      label="Гражданство ребенка"
    >
      <Dropdown
        v-model="country"
        filter
        show-clear
        option-label="title"
        option-value="code"
        :options="citizenshipOptions"
      />
    </BaseInputForm>

    <BaseInputForm
      class="col-span-4"
      label="Дата установки инвалидности, если есть"
    >
      <InputDate v-model="disability_date" />
    </BaseInputForm>

    <BaseCheckboxForm
      class="col-span-full"
      label="проживает совместно"
    >
      <Checkbox
        v-model="cohabitation"
        binary
      />
    </BaseCheckboxForm>
  </div>
</template>

<script setup lang="ts">
import { BaseCheckboxForm, BaseInputForm, DictList, InputDate, InputFio, useGeneralGetDict } from '@/shared'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import { defineModel, ref } from 'vue'

const lastname = defineModel<string>('lastname')
const firstname = defineModel<string>('firstname')
const middlename = defineModel<string>('middlename')
const disability_date = defineModel<string | null>('disability_date')
const birthday = defineModel<string | null>('birthday')
const country = defineModel<string>('country')
const cohabitation = defineModel<boolean>('cohabitation')

/** Справочник по гражданствам */
const citizenshipOptions = ref<DictList>()

useGeneralGetDict('Country').then(dict => {
  if (dict) {
    citizenshipOptions.value = dict
  }
})
</script>
