export * from './api-mortgage.types'

import { mortgageApi } from './api-mortgage'


let api: ReturnType<typeof createApi>

export const useApi = () => {
  if (!api) createApi()
  return api
}

const createApi = () => {
  const returnData = {
    mortgage: mortgageApi,
  }

  api = returnData
  return returnData
}



export * from './bokerclient'
export * from './commondicts'
export * from './customer'
export * from './dadata'
export * from './general'
export * from './utils'
export * from './salespoint'
export * from './user'