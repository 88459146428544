<template>
  <InputNumber
    v-model="years"
    suffix=" лет"
    :invalid="invalid"
    :readonly="readonly"
    :disabled="disabled"
    :placeholder="placeholder"
    :inputId="inputId"
    :pt="pt"
    :min="min"
    :max="99"
    :use-grouping="false"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
  />
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue'
import InputNumber, { InputNumberBlurEvent, InputNumberPassThroughOptions } from 'primevue/inputnumber'
import { convertMonthsToYears, convertYearsToMonths } from '@/shared'

const props = defineProps<{
  invalid?: boolean
  readonly?: boolean
  disabled?: boolean
  placeholder?: string
  inputId?: string
  pt?: InputNumberPassThroughOptions

  min?: number
}>()

const emits = defineEmits<{
  blur: [InputNumberBlurEvent]
  focus: [Event]
}>()

const month = defineModel<number | null>()
const years = ref<number>()

watch(
  month,
  month => {
    if (month && month < 12) {
      month = 12
    }
    years.value = month ? convertMonthsToYears(month) : undefined
  },
  { immediate: true }
)

watch(years, years => (month.value = convertYearsToMonths(years || 0)))
</script>
