import { routesNames } from '../../routes'
import { useAuthModel } from '@/features/auth'
import { storeToRefs } from 'pinia'

export async function authGuard() {
  const authModel = useAuthModel()

  const token = localStorage.getItem('token')
  const { isAuthorized } = storeToRefs(authModel)
  const { checkLogin } = authModel

  if (!token) {
    return { name: routesNames.account.login }
  }

  if (!isAuthorized.value) {
    const res = await checkLogin()

    if (!res?._data) {
      return { name: routesNames.account.login }
    }
  }

  return true
}
