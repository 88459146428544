import { ref, computed, readonly, watch, Ref } from 'vue'

export type ControlError = string | null

export type ControlType = 'checkbox' | 'input'

export type ControlValidator<VType = any> = (value: VType) => true | string

export type ControlCreateOptions<VType = unknown> = {
  initialValue?: VType | null
  validators?: ControlValidator[]
  type?: ControlType
  // type?: ControlType
}

export type ControlSetValueOptions = {
  setTouched: boolean
}

export type ControlSetErrorOptions = {
  setTouched: boolean
}

export type ControlAddValidatorsOptions = {
  validate: boolean
}

export type ControlValidationOptions = {
  setTouched: boolean
}

export type Control<T> = {
  model: Ref<T | null>
  meta: {
    readonly name: string
    readonly valid: boolean
    readonly invalid: boolean
    readonly error: ControlError
    readonly disabled: boolean
    readonly touched: boolean
    readonly dirty: boolean
  }
  _meta: {
    readonly error: ControlError
    readonly valid: boolean
    readonly invalid: boolean
  }
  validation(options?: ControlValidationOptions): boolean
  enable(): void
  reset(): void
  disable(): void
  onBlur(): void
  onChange(): void
  removeValidators(removedValidators?: ControlValidator[], options?: ControlAddValidatorsOptions): void
  addValidators(newValidators: ControlValidator[], options?: ControlAddValidatorsOptions): void
  haveValidator(validator: ControlValidator): boolean
  setValue(value: T | null, options?: ControlSetValueOptions): void
  setError(error: string | string[], options?: ControlSetErrorOptions): void
}

export type UnwrapControl<T> = Omit<Control<T>, 'model'> & {
  model: T | null
}

export function useControl<ModelType = unknown>(
  name: string,
  options?: ControlCreateOptions<ModelType>
): Control<ModelType> {

  const getInitialValue = () => {
    if (!options?.initialValue && options?.type === 'checkbox') {
      return false
    } 
    
    return options?.initialValue || null
  } 

  const model = ref<ModelType | null | boolean>(getInitialValue()) as Ref<ModelType | null>

  const validators = ref<ControlValidator[] | null>(options?.validators || null)
  // const type = ref<ControlType>(options?.type || 'input')

  // Закрытые мета данные
  const _error = ref<ControlError>(null)
  const _valid = computed(() => (_error.value ? false : true))
  const _invalid = computed(() => !_valid.value)

  // Открытые мета данные
  const dirty = ref<boolean>(false)
  const touched = ref<boolean>(false)
  const disabled = ref<boolean>(false)
  const valid = computed(() => {
    if (touched.value && !disabled.value) {
      return _valid.value
    }

    return true
  })

  const invalid = computed(() => !valid.value)
  const error = computed(() => {
    if (meta.touched && !disabled.value) {
      return _error.value
    }

    return null
  })

  // Мета данные поля открытие
  const meta = readonly({
    name,
    valid,
    invalid,
    error,
    disabled,
    touched,
    dirty
  })

  // Мета данные поля закрытые
  const _meta = readonly({
    error: _error,
    valid: _valid,
    invalid: _invalid
  })

  watch(model, () => validation())
  validation()

  function validation(options: ControlValidationOptions = { setTouched: false }) {
    if (options.setTouched) {
      touched.value = true
    }

    if (validators.value) {
      for (let i = 0; i < validators.value.length; i++) {
        const validationError = validators.value[i](model.value)
        if (validationError !== true) {
          _error.value = validationError
          return false
        }
      }
    }

    _error.value = null
    return true
  }

  function onBlur() {
    touched.value = true
    validation()
  }

  function onChange() {
    dirty.value = true
  }

  function disable() {
    disabled.value = true
  }

  function enable() {
    disabled.value = false
  }

  function addValidators(newValidators: ControlValidator[], options: ControlAddValidatorsOptions = { validate: true }) {
    if (!validators.value) {
      validators.value = []
    }

    validators.value?.push(...newValidators)

    if (options.validate) {
      validation()
    }
  }

  function removeValidators(
    removedValidators: ControlValidator[] = [],
    options: ControlAddValidatorsOptions = { validate: true }
  ) {
    if (validators.value?.length) {
      removedValidators.forEach(removedValidator => {
        const i = validators.value?.findIndex(validatorFn => validatorFn.toString() === removedValidator.toString())
        if (i !== undefined && i !== -1) {
          validators.value?.splice(i, 1)
        }
      })
    } else {
      validators.value = []
    }

    if (options.validate) {
      validation()
    }
  }

  function setValue(value: ModelType | null, options: ControlSetValueOptions = { setTouched: true }) {
    model.value = value
    if (options.setTouched) {
      touched.value = true
    }
  }

  function reset() {
    model.value = null
    touched.value = false
    dirty.value = false
    _error.value = null
    validation()
  }

  function setError(error: string | string[], options: ControlSetErrorOptions = { setTouched: true }) {
    _error.value = Array.isArray(error) ? error[0] : error
    if (options.setTouched) {
      touched.value = true
    }
  }

  function haveValidator(fn: ControlValidator) {
    if (validators.value?.length) {
      if (validators.value?.find(validatorFn => validatorFn.toString() === fn.toString())) {
        return true
      }
    }

    return false
  }

  return {
    meta,
    _meta,

    model,

    reset,
    enable,
    disable,
    setError,
    setValue,
    validation,
    haveValidator,
    addValidators,
    removeValidators,

    onBlur,
    onChange
  }
}
