<template>
  <Panel
    v-model:collapsed="collapsed"
    v-tooltip.bottom="tooltip"
    :pt="{
      root: 'bg-white border border-solid border-gray-200 rounded-s',
      header: headerClass,
      content: 'border-0 p-8'
    }"
  >
    <template #header>
      <div
        class="w-full"
        :class="{
          'opacity-40 pointer-events-none': disabled
        }"
      >
        <div
          class="w-full cursor-pointer p-8"
          @click="toggle"
        >
          <slot name="header"></slot>
        </div>
      </div>
    </template>
    <slot name="content"></slot>
  </Panel>
</template>

<script setup lang="ts">
import Panel from 'primevue/panel'
import { computed, defineModel, toRef } from 'vue'

const props = defineProps<{
  disabled?: boolean
  tooltip?: string | null
}>()

const disabled = toRef(props, 'disabled')
const collapsed = defineModel<boolean>('collapsed', { default: true })

// const root

/** Стили для шапки панели в зависимости от collapsed */
const headerClass = computed(() => {
  let baseClass = 'bg-transparent p-0'

  if (collapsed.value) {
    return baseClass + ' border-0'
  } else {
    return baseClass + ' border-b border-0 border-solid border-gray-200'
  }
})

function toggle(event: MouseEvent) {
  const target = event.target as Element
  const offsetParent = target.parentElement
  if (target.classList.contains('p-button') || offsetParent?.classList.contains('p-button')) {
    return
  }

  collapsed.value = !collapsed.value
}
</script>
