import { reactive } from 'vue'
import { CommondictsCustomerDictName, DictList, useGetCommondictsCustomerDicts } from '@/shared'

const dictState = reactive<{ [key in CommondictsCustomerDictName]?: DictList }>({})

/**
 * справочники кастомера
 */
export const customerDicts = new Proxy(dictState, {
  get(target, prop: CommondictsCustomerDictName) {
    if (typeof prop === 'symbol' || prop.includes('__')) {
      return target[prop]
    }

    if (!target[prop]) {
      useGetCommondictsCustomerDicts(prop)
        .post()
        .then(res => {
          dictState[prop] = res?._data
        })
    }

    return target[prop]
  }
})
